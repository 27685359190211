import type { IEventUnsubscriber } from "./IEventUnsubscriber";
import type { IEventMapper } from "./IEventMapper";
import type { SupportedEvents } from "./SupportedEvents";
import type { IEventSubscriber } from "./IEventSubscriber";

export function EventGenericSubscriber(
    eventMapper: IEventMapper,
    nativeSubscriber: IEventSubscriber,
    videoSubscriber: IEventSubscriber,
    customSubscriber: IEventSubscriber
): IEventSubscriber {
    return {
        on(
            genericEventName: SupportedEvents,
            eventListener: EventListener,
            once = false
        ) {
            const eventMap = eventMapper[genericEventName];
            const subscriptions: IEventUnsubscriber[] = [];
            let wrappedListener = eventListener;
            if (once) {
                wrappedListener = (...args) => {
                    eventListener(...args);
                    off();
                };
            }

            eventMap.nativeEvents.forEach((event) => {
                subscriptions.push(
                    nativeSubscriber?.on(event, wrappedListener)
                );
            });

            eventMap.videoEvents.forEach((event) => {
                subscriptions.push(videoSubscriber?.on(event, wrappedListener));
            });

            eventMap.customEvents.forEach((event) => {
                subscriptions.push(
                    customSubscriber?.on(event, wrappedListener)
                );
            });

            return {
                off,
            };

            function off() {
                subscriptions.forEach((sub) => {
                    sub.off();
                });
            }
        },
    };
}
