<svelte:options tag="vch-player" />

<script context="module">
    getExtendedLocalStorage();
</script>

<script lang="ts">
    import { onDestroy, onMount, setContext, tick } from "svelte";
    import type { Writable } from "svelte/store";
    import { writable } from "svelte/store";

    import type { IVuPlayer } from "../../../modules/vu-player-models/player/IVuPlayer";
    import type { State } from "./types/state";
    import { loadShaka } from "../../../modules/shaka-3/ShakaSDKLoader";
    import { ErrorsLogger } from "./utils/errors-logger";
    import { PlayerAdapter } from "./adapter";
    import { SupportedEvents } from "./../../../modules/vu-events/SupportedEvents";
    import { getStreamType } from "./../../../modules/vu-core/stream/get-stream-type";
    import { delay } from "./../../../modules/vu-core/utils/delay";
    import { getExtendedLocalStorage } from "./../../../modules/vu-core/storage/get-extended-local-storage";

    import VchSkin from "./components/VchSkin.svelte";
    import { isAttributeTrue } from "./utils/utils";
    import { localStorageKeys } from "./utils/constants";
    import type { SkinConfig } from "./types/skin-config";
    import { UTCToHHMMSS } from "../../../modules/vu-core/date-time/utc-to-hhmmss";
    import { toHHMMSS } from "../../../modules/vu-core/date-time/to-hhmmss";

    let errorHandler;

    let showInfoBox = writable(false);
    let state: Writable<State> = writable({
        now: Date.now(),
        hasCanplayFired: false,
        playerState: "uninitialised",
        isAbrEnabled: true,
        isLive: false,
        isMuted: false,
        volume: 1,
        errors: [],
        videoTracks: [],
        audioTracks: [],
        textTracks: [],

        defaultFrameRate: 25,
        showInfoBox,
        showClipControls: false,

        isFullscreen: false,
        playerWidth: 0,
        playerHeight: 0,
        isSettingsOpen: false,
        isOverlayHidden: false,
        isSubMenuOpen: false,
        liveTimeFormatter: UTCToHHMMSS,
        vodCurrentTimeFormatter: toHHMMSS,
        vodDurationFormatter: toHHMMSS,
    });
    let videoContainer;

    export let adapter: IVuPlayer;

    export let info: string | boolean = localStorage.getPrimitiveItemOrDefault(
        localStorageKeys.showInfoBox,
        false
    );
    export let defaultFrameRate = 25;
    export let clip: string | boolean = false;
    export let muted: string | boolean = true;

    setContext("state", state);

    onMount(async () => {
        await loadShaka();
        await tick();

        // TODO: find a better solution
        await delay(100);
        updateAttributes();
    });

    onDestroy(() => {
        errorHandler.unbind();
    });

    function onCanPlay() {
        $state.hasCanplayFired = true;

        updateTracks();
        onTimeUpdate();
    }

    function onStateChange() {
        $state.playerState = adapter.state;

        updateTracks();
    }

    function onTimeUpdate() {
        updateTimes();
    }

    function onSourceLoaded() {
        $state.sourceType = getStreamType(adapter.src);
        $state.hasCanplayFired = true;

        updateTracks();
        updateTimes();
    }

    function onVolumeChange() {
        $state.isMuted = adapter.isMuted;
        $state.volume = adapter.isMuted ? 0 : adapter.volume;
    }

    function onError(error) {
        $state.errors = [...$state.errors, error];
    }

    function updateTimes() {
        $state.currentUTC = adapter.currentUTC;
        $state.currentTime = adapter.currentTime;
        $state.dvr = adapter.dvr;
        $state.duration = adapter.duration;
        $state.seekableRange = adapter.seekableRange;
    }

    function onProgress() {
        // Good enough to update viedo quality every n seconds
        updateTracks();
    }

    function updateTracks() {
        $state.activeVideoTrack = adapter.activeVideoTrack;
        $state.activeAudioTrack = adapter.activeAudioTrack;
        $state.activeTextTrack = adapter.activeTextTrack;

        $state.videoTracks = adapter.videoTracks;
        $state.audioTracks = adapter.audioTracks;
        $state.textTracks = adapter.textTracks;

        $state.isAbrEnabled = adapter.abr ?? true;
    }

    function updateAttributes() {
        $state.defaultFrameRate = defaultFrameRate ?? 25;
        $state.showClipControls = isAttributeTrue(clip);

        $showInfoBox = isAttributeTrue(info);
        showInfoBox.subscribe(storeShowInfoBox);
    }

    function storeShowInfoBox(value) {
        localStorage.setPrimitiveItem(localStorageKeys.showInfoBox, value);
    }

    function subscribe() {
        adapter.on(SupportedEvents.CanPlay, onCanPlay);
        adapter.on(SupportedEvents.StateChange, onStateChange);
        adapter.on(SupportedEvents.VideoTrackChange, updateTracks);
        adapter.on(SupportedEvents.AudioTrackChange, updateTracks);
        adapter.on(SupportedEvents.AudioTrackAdded, updateTracks);
        adapter.on(SupportedEvents.TextTrackChange, updateTracks);
        adapter.on(SupportedEvents.TimeUpdate, onTimeUpdate);
        adapter.on(SupportedEvents.SourceLoaded, onSourceLoaded);
        adapter.on(SupportedEvents.VolumeChange, onVolumeChange);
        adapter.on(SupportedEvents.Progress, onProgress);

        errorHandler = new ErrorsLogger(onError, adapter);
    }

    function setInitialSkinState() {
        isAttributeTrue(muted) ? adapter.mute() : adapter.unmute();
    }

    export async function setSkin(skinConfig: SkinConfig) {
        if (skinConfig.liveTimeFormatter) {
            $state.liveTimeFormatter = skinConfig.liveTimeFormatter;
        }

        if (skinConfig.vodCurrentTimeFormatter) {
            $state.vodCurrentTimeFormatter = skinConfig.vodCurrentTimeFormatter;
        }

        if (skinConfig.vodDurationFormatter) {
            $state.vodDurationFormatter = skinConfig.vodDurationFormatter;
        }

        return Promise.resolve();
    }

    export async function init(playerConfig = {}, isLive = false) {
        await loadShaka();

        $state.playerConfig = playerConfig;
        $state.isLive = isLive;
        $state.adapter = PlayerAdapter(
            {
                ...playerConfig,
                container: videoContainer,
            },
            isLive
        );

        adapter = $state.adapter as IVuPlayer;

        subscribe();

        return Promise.resolve();
    }

    export async function load(sourceConfig) {
        $state.sourceConfig = sourceConfig;
        return $state.adapter?.load(sourceConfig).then(setInitialSkinState);
    }
</script>

<VchSkin>
    <div class="video-container" bind:this={videoContainer} />
</VchSkin>

<style lang="scss">
    .video-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        background-color: black;
    }
</style>
