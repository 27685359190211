import { _throw } from "../vu-core/error/throw";
import { assertTruthy } from "../vu-core/validation/assert";
import type { PlayerConfig } from "../vu-player-models/configuration/player-config";
import type { PlayerConfigurationMapper } from "../vu-player-models/configuration/player-configuration-mapper";
import { MapPlayerConfiguration } from "./MapConfiguration";

export function PlayerInstanceFactory(
    configuration: PlayerConfig,
    configurationMapper: PlayerConfigurationMapper = MapPlayerConfiguration
): [any, HTMLVideoElement] {
    assertTruthy(
        configuration?.container?.tagName === "DIV",
        "Container must be of type DIV."
    );

    assertTruthy(
        (window as any).shaka,
        "shaka library is not loaded properly."
    );

    shaka.polyfill.installAll();
    assertTruthy(
        shaka.Player.isBrowserSupported(),
        "Shaka player does not support this browser!"
    );

    if (!!configuration.controls) {
        _throw({
            skipThrow: true,
            error: "Shaka default ui is not available!",
        });
    }

    const video = document.createElement("video");
    video.setAttribute("style", "width: 100%; height: 100%;");
    configuration.container.appendChild(video);

    if (configuration.autoplay) {
        video.autoplay = true;
    }

    if (configuration.loop) {
        video.loop = true;
    }

    if (configuration.poster) {
        video.poster = configuration.poster;
    }

    let shakaConfig: any = configurationMapper(configuration);
    const player = new shaka.Player(video);
    player.configure(shakaConfig);

    return [player, video];
}
