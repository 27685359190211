<script lang="ts">
    import { SupportedEvents } from "../../../../../../modules/vu-events/SupportedEvents";
    import { getContext, onDestroy } from "svelte";
    import type { Writable } from "svelte/store";
    import type { State } from "../../../types/state";

    let state: Writable<State> = getContext("state");

    import VchButton from "../../shared/VchButton.svelte";

    let canvas;
    let canvasCtx;
    let audioCtx;
    let analyser;
    let bufferLength;
    let dataArray;
    let animationFrameRequest;

    let initiated = false;
    let progressSubscription;

    $: videoElement = $state.adapter?.video;

    onDestroy(() => {
        cancelAnimationFrame(animationFrameRequest);
        progressSubscription?.off();
    });

    function start() {
        if (videoElement?.readyState === 4) {
            setup(videoElement);
        } else {
            subscribe();
        }
    }

    function subscribe() {
        progressSubscription = $state.adapter?.on(
            SupportedEvents.Progress,
            onProgressHandler
        );
    }

    function onProgressHandler() {
        if (videoElement?.readyState !== 4) return;
        progressSubscription?.off();
        setup(videoElement);
    }

    function setup(videoElement) {
        if (typeof videoElement.captureStream !== "function") return;

        setupCanvasRenderingContext();
        createSineAnalyser();
        drawSine();

        initiated = true;
    }

    function setupCanvasRenderingContext() {
        canvasCtx = canvas.getContext("2d");
        canvasCtx.fillStyle = "rgba(0, 60, 105, 0.4)";
        canvasCtx.strokeStyle = "rgb(0, 153, 204)";
        canvasCtx.lineWidth = 2;
    }
    function createSineAnalyser() {
        audioCtx = new (window.AudioContext ||
            (window as any).webkitAudioContext)();

        analyser = audioCtx.createAnalyser();

        const source = (videoElement as any)?.captureStream();
        const stream = audioCtx.createMediaStreamSource(source);
        stream.connect(analyser);

        analyser.fftSize = 2048;
        bufferLength = analyser.frequencyBinCount;
        dataArray = new Uint8Array(bufferLength);
    }
    function drawSine() {
        analyser.getByteTimeDomainData(dataArray);

        canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
        canvasCtx.fillRect(0, 0, canvas.width, canvas.height);
        canvasCtx.beginPath();

        const sliceWidth = (canvas.width * 1.0) / bufferLength;
        let x = 0;

        for (let i = 0; i < bufferLength; i++) {
            const v = dataArray[i] / 128.0;
            const y = (v * canvas.height) / 2;
            if (i === 0) {
                canvasCtx.moveTo(x, y);
            } else {
                canvasCtx.lineTo(x, y);
            }
            x += sliceWidth;
        }

        canvasCtx.lineTo(canvas.width, canvas.height / 2);
        canvasCtx.stroke();

        animationFrameRequest = requestAnimationFrame(() => drawSine());
    }
</script>

<div class="vch-audio-graph">
    {#if !initiated}
        <VchButton label="Show audiograph" on:click={start}>start</VchButton>
    {/if}
    <canvas class:show={initiated} bind:this={canvas} width={320} height={36} />
</div>

<style lang="scss">
    .vch-audio-graph {
        display: block;
        pointer-events: none;
        width: 100%;
        height: 3em;

        canvas {
            width: 100%;
            height: 100%;
            display: none;

            &.show {
                display: block;
            }
        }
    }
</style>
