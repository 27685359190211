import type { IEventSubscriber } from "../../vu-events/IEventSubscriber";
import type { IInstanceProvider } from "../../vu-player-models/configuration/instance-provider";

export function NativeEventSubscriber(
    instanceProvider: IInstanceProvider
): IEventSubscriber {
    return {
        on(nativeEventName: string, eventListener: EventListener) {
            const nativePlayer = instanceProvider();
            const eventManager = new shaka.util.EventManager();
            eventManager.listen(nativePlayer, nativeEventName, eventListener);

            return {
                off() {
                    eventManager.unlisten(
                        nativePlayer,
                        nativeEventName,
                        eventListener
                    );
                },
            };
        },
    };
}
