import { isArrayAndNotEmpty } from "../../vu-core/validation/is-array-and-not-empty";
import { isNullOrUndefined } from "../../vu-core/validation/is-null-or-undefined";
import type { IInstanceProvider } from "../../vu-player-models/configuration/instance-provider";
import type { ITextTrackService } from "../../vu-player-models/services/ITextTrackService";
import { EmptyTextTrack } from "../../vu-player-models/tracks/text-track";
import type { TextTrack } from "../../vu-player-models/tracks/text-track";

export class TextTrackService implements ITextTrackService {
    constructor(private readonly instanceProvider: IInstanceProvider) {}
    setTextTrack(textTrack: TextTrack): Promise<void> {
        if (isNullOrUndefined(textTrack)) {
            this.instance.setTextTrackVisibility(false);
            return Promise.resolve();
        }

        const tracks = this.instance.getTextTracks();
        const textTrackID = Number((<TextTrack>textTrack).id);
        const track = tracks.find((track) => {
            return track.id === textTrackID;
        });

        if (isNullOrUndefined(track)) {
            return Promise.reject("This text track doesn't exist!");
        }

        this.instance.selectTextTrack(track);
        this.instance.setTextTrackVisibility(true);

        return Promise.resolve();
    }

    public get textTracks(): TextTrack[] {
        let tracks = this.instance.getTextTracks();
        if (!isArrayAndNotEmpty(tracks)) {
            return [];
        }
        return tracks.map(_mapTextTrack);
    }

    public get activeTextTrack(): TextTrack {
        const activeTextTrack = this.textTracks.find((textTrack) => {
            return textTrack.isActive;
        });

        if (isNullOrUndefined(activeTextTrack)) {
            return EmptyTextTrack;
        }
        return activeTextTrack!;
    }

    private get instance(): any {
        return this.instanceProvider();
    }
}
function _mapTextTrack({ id, label, language, kind, active }: any): TextTrack {
    return {
        id: String(id),
        lang: language,
        label,
        kind,
        isActive: active,
    };
}

// var shakaTextTrackSample = {
//     active: false,
//     audioBandwidth: null,
//     audioCodec: null,
//     audioId: null,
//     audioRoles: null,
//     audioSamplingRate: null,
//     bandwidth: 0,
//     channelsCount: null,
//     codecs: "stpp",
//     frameRate: null,
//     height: null,
//     id: 3,
//     kind: "subtitle",
//     label: null,
//     language: "en",
//     mimeType: "application/mp4",
//     originalAudioId: null,
//     originalTextId: "textstream_eng=1000",
//     originalVideoId: null,
//     pixelAspectRatio: null,
//     primary: true,
//     roles: ["main"],
//     type: "text",
//     videoBandwidth: null,
//     videoCodec: null,
//     videoId: null,
//     width: null,
// };
