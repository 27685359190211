import { isArrayAndNotEmpty } from "../../vu-core/validation/is-array-and-not-empty";
import { isNullOrUndefined } from "../../vu-core/validation/is-null-or-undefined";
import type { IInstanceProvider } from "../../vu-player-models/configuration/instance-provider";
import type { IVideoTrackService } from "../../vu-player-models/services/IVideoTrackService";
import type { VideoTrack } from "../../vu-player-models/tracks/video-track";
import { EmptyVideoTrack } from "../../vu-player-models/tracks/video-track";

export class VideoTrackService implements IVideoTrackService {
    constructor(public instanceProvider: IInstanceProvider) {}

    private get instance(): any {
        return this.instanceProvider();
    }

    get videoTracks(): VideoTrack[] {
        let variantTracks = this.instance.getVariantTracks();

        if (!isArrayAndNotEmpty(variantTracks)) {
            return [];
        }

        return variantTracks.reduce(filterVariantTracks, []);
    }

    get activeVideoTrack(): VideoTrack {
        let track: unknown = this.videoTracks.find((videoTrack): boolean => {
            return videoTrack.isActive;
        });

        if (isNullOrUndefined(track)) {
            return EmptyVideoTrack;
        }

        return track as VideoTrack;
    }

    setAbr(enable: boolean): Promise<void> {
        this.instance.configure({
            abr: {
                enabled: enable,
            },
        });
        return Promise.resolve();
    }

    get abr(): boolean {
        const configuration = this.instance.getConfiguration();
        return !!configuration?.abr?.enabled;
    }

    setVideoTrack(videoTrack: VideoTrack): Promise<void> {
        let variantTrack = this.instance.getVariantTracks().find((track) => {
            return track.videoId === Number(videoTrack.id);
        });

        if (isNullOrUndefined(variantTrack)) {
            return Promise.reject("This video track doesn't exist!");
        }

        if (this.abr) {
            console.warn(
                "The select video track will be overriden because abr is enabled!"
            );
        }

        this.instance.selectVariantTrack(variantTrack);
        return Promise.resolve();
    }
}

function filterVariantTracks(audioTracks, currentVariantTrack): VideoTrack[] {
    const mappedVariantTrack = mapVideoTrack(currentVariantTrack);
    let audioTrackAlreadyAdded = audioTracks.find(
        (audioTrack: VideoTrack): boolean => {
            // Comment: because of the variantTracks matrix https://shaka-player-demo.appspot.com/docs/api/shakaExtern.html#.Track
            return (
                audioTrack.id === mappedVariantTrack.id &&
                audioTrack.label === mappedVariantTrack.label &&
                audioTrack.bitrate === mappedVariantTrack.bitrate
            );
        }
    );

    if (!audioTrackAlreadyAdded) {
        audioTracks.push(mappedVariantTrack);
        return audioTracks;
    }

    if (!!mappedVariantTrack.isActive) {
        audioTrackAlreadyAdded.isActive = true;
    }
    return audioTracks;
}

function mapVideoTrack({
    videoId,
    videoBandwidth,
    height,
    width,
    label,
    active,
    frameRate,
}): VideoTrack {
    return {
        id: String(videoId),
        bitrate: videoBandwidth,
        height,
        width,
        label,
        isActive: !!active,
        frameRate: frameRate ?? NaN,
    };
}
