<script lang="ts">
    import { getContext } from "svelte";
    import type { Writable } from "svelte/store";
    import { fade } from "svelte/transition";
    import type { State } from "../../types/state";

    let state: Writable<State> = getContext("state");

    $: showOverlay = $state.isSettingsOpen;
</script>

{#if showOverlay}
    <div class="vch-overlay" transition:fade={{ duration: 800 }} />
{/if}

<style type="text/scss" lang="scss">
    @import "./../../globals/_global-variables.scss";
    @import "./../../globals/mixins.scss";

    .vch-overlay {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: var(--v-overlay-opacity, $overlay-opacity);
    }
</style>
