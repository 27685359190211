<script lang="ts">
    import { getContext } from "svelte";
    import type { Writable } from "svelte/store";
    import type { State } from "../../../../types/state";
    let state: Writable<State> = getContext("state");
</script>

<div class="vch-errors-menu">
    {#if $state.errors.length > 0}
        {#each $state.errors as error}
            <div class="log">{error}</div>
        {/each}
    {:else}
        <span>No errors occured!</span>
    {/if}
</div>

<style lang="scss">
    .vch-errors-menu {
        .log {
            width: calc(100% - 1em);
            margin: 0.3em 0;
            background-color: rgba(101, 101, 101, 0.9);
            padding: 0.5em;
        }
    }
</style>
