import type { DRMProviderConfig } from "../../vu-player-models/drm/drm-provider-config";

export function createWidevineConfiguration(
    token: string,
    provider: DRMProviderConfig = {
        laURL: "https://widevine-license.vudrm.tech/proxy",
    }
) {
    return {
        "com.widevine.alpha": `${provider?.laURL}?token=${encodeURIComponent(
            token
        )}`,
    };
}
