<script lang="ts">
    import { getContext } from "svelte";
    import { toggleFullscreen } from "../../../../../modules/vu-core/dom/fullscreen";
    import type { Writable } from "svelte/store";
    import type { State } from "../../types/state";
    let state: Writable<State> = getContext("state");

    import VchButton from "../shared/VchButton.svelte";
    import VchIcon from "../shared/VchIcon.svelte";

    $: type = $state.isFullscreen ? "fullScreenExit" : "fullScreenEnter";

    function click() {
        toggleFullscreen($state.playerContainer);
    }
</script>

<VchButton on:click={click} label="toggle fullscreen (f)">
    <VchIcon {type} />
</VchButton>
