<script lang="ts">
    import { getContext } from "svelte";
    import type { Writable } from "svelte/store";
    import type { State } from "../../types/state";
    let state: Writable<State> = getContext("state");

    import VchButton from "./../shared/VchButton.svelte";

    $: duration = $state.vodDurationFormatter($state.duration);
    $: currentTime = $state.isLive
        ? $state.liveTimeFormatter($state.adapter?.currentUTC)
        : $state.vodCurrentTimeFormatter($state.adapter?.currentTime);

    function goLive() {
        $state.adapter?.goToLive();
    }
</script>

<div class="vch-time">
    {#if $state.isLive}
        <div>
            <VchButton label="live" on:click={goLive}>LIVE</VchButton>
            &nbsp;<time datetime={currentTime}>{currentTime}</time>
        </div>
    {:else}
        <div>
            <time datetime={currentTime}>{currentTime}</time>&nbsp;/&nbsp;
            <time datetime={duration}>{duration}</time>
        </div>
    {/if}
</div>

<style lang="scss">
    .vch-time {
        user-select: none;
        height: 3em;
        padding: 0 0.5em;

        div {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
        }

        * {
            font-size: 0.97em;
        }
    }
</style>
