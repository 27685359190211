export function getExtension(uri: string = ""): string {
    if (uri === "") {
        return "";
    }

    let url = new URL(uri);
    let path = url.pathname;
    let splitPath = path.split("/");
    let lastItem = splitPath.pop();
    let splitLastItem = (lastItem && lastItem.split(".")) || [];
    let format = splitLastItem.pop() || "";

    return format;
}
