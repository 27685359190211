<script lang="ts">
    import { getContext } from "svelte";
    import type { Writable } from "svelte/store";
    import type { State } from "../../../types/state";
    import {
        formatAudioTrack,
        formatTextTrack,
        formatVideoTrack,
    } from "../../../utils/tracks";
    let state: Writable<State> = getContext("state");
    let stateShowInfoBox = $state.showInfoBox;

    import VchButton from "../../shared/VchButton.svelte";
    import VchIcon from "../../shared/VchIcon.svelte";
    import VchVideoTracksMenu from "./subMenus/VchVideoTracksMenu.svelte";
    import VchAudioTracksMenu from "./subMenus/VchAudioTracksMenu.svelte";
    import VchTextTracksMenu from "./subMenus/VchTextTracksMenu.svelte";
    import VchMiscMenu from "./subMenus/VchMiscMenu.svelte";
    import VchErrorsMenu from "./subMenus/VchErrorsMenu.svelte";

    let selectedTab;
    const titleMappings = {
        audio: "Audio track",
        video: "Video quality",
        text: "Subtitles",
        misc: "Misc",
        errors: "Errors",
    };

    $: overlayTitle = titleMappings[selectedTab];

    function showSubOverlay(subMenuType: string) {
        $state.isSubMenuOpen = true;
        selectedTab = subMenuType;
    }

    function showRootMenu() {
        $state.isSubMenuOpen = false;
    }

    function toggleShowInfoBox() {
        $stateShowInfoBox = !$stateShowInfoBox;
    }
</script>

{#if $state.isSettingsOpen}
    <div class="vch-settings-overlay">
        {#if !$state.isSubMenuOpen}
            <div class="overlay rootMenu">
                <div
                    class="rootMenu--category clickable"
                    on:click={toggleShowInfoBox}
                    aria-label="Toggle info box"
                >
                    <input
                        type="checkbox"
                        bind:checked={$stateShowInfoBox}
                        class="rootMenu--showInfo"
                        aria-label="Toggle info box"
                    />
                    <span> Info box</span>
                </div>

                <div
                    class="rootMenu--category clickable"
                    on:click={() => showSubOverlay("video")}
                    aria-label="open video track settings"
                >
                    <VchButton
                        label="open video track settings"
                        on:click={() => showSubOverlay("video")}
                    >
                        <VchIcon type="videoQuality" />
                    </VchButton>
                    <span>
                        {formatVideoTrack(
                            $state.activeVideoTrack,
                            $state.adapter?.abr ?? true
                        )}
                    </span>
                </div>
                <div
                    class="rootMenu--category clickable"
                    on:click={() => showSubOverlay("audio")}
                    aria-label="open audio track settings"
                >
                    <VchButton
                        label="open audio track settings"
                        on:click={() => showSubOverlay("audio")}
                    >
                        <VchIcon type="speaker" />
                    </VchButton>
                    <span>
                        {formatAudioTrack($state.activeAudioTrack)}
                    </span>
                </div>
                <div
                    class="rootMenu--category clickable"
                    on:click={() => showSubOverlay("text")}
                    aria-label="open text track settings"
                >
                    <VchButton
                        label="open text track settings"
                        on:click={() => showSubOverlay("text")}
                    >
                        <VchIcon type="captions" />
                    </VchButton>
                    <span>
                        {formatTextTrack($state.activeTextTrack)}
                    </span>
                </div>
                <div
                    class="rootMenu--category clickable"
                    on:click={() => showSubOverlay("misc")}
                    aria-label="open misc menu"
                >
                    <VchButton
                        label="open misc menu"
                        on:click={() => showSubOverlay("misc")}
                    >
                        <VchIcon type="miscellaneous" />
                    </VchButton>
                    <span>Miscellaneous</span>
                </div>
                <div
                    class="rootMenu--category clickable"
                    on:click={() => showSubOverlay("errors")}
                    aria-label="open errors menu"
                >
                    <VchButton
                        label="open errors menu"
                        on:click={() => showSubOverlay("errors")}
                    >
                        <VchIcon type="errorOutline" />
                    </VchButton>
                    <span>Errors ({$state.errors.length})</span>
                </div>
            </div>
        {:else}
            <div class="overlay subMenu">
                <div
                    class="backMenu clickable"
                    on:click={showRootMenu}
                    aria-label="Back to settings menu"
                >
                    <VchButton
                        label="Back to settings menu"
                        on:click={showRootMenu}
                    >
                        <VchIcon type="caretLeft" />
                    </VchButton>
                    <span>{overlayTitle}</span>
                </div>
                <div class="tabContent" class:visible={selectedTab === "video"}>
                    <VchVideoTracksMenu />
                </div>
                <div class="tabContent" class:visible={selectedTab === "audio"}>
                    <VchAudioTracksMenu />
                </div>
                <div class="tabContent" class:visible={selectedTab === "text"}>
                    <VchTextTracksMenu />
                </div>
                <div class="tabContent" class:visible={selectedTab === "misc"}>
                    <VchMiscMenu />
                </div>
                <div
                    class="tabContent"
                    class:visible={selectedTab === "errors"}
                >
                    <VchErrorsMenu />
                </div>
            </div>
        {/if}
    </div>
{/if}

<style lang="scss">
    @import "./../../../globals/_global-variables.scss";

    .vch-settings-overlay {
        position: absolute;
        width: 100%;
        height: calc(100% - 4.5em);
        color: #fff;
        box-sizing: border-box;
        top: 0;
        display: flex;
        padding: 1em;
        box-sizing: border-box;

        .overlay {
            width: 100%;
            overflow-y: auto;
            overflow-x: visible;

            padding: 5px; // TODO: Review if really needed
        }

        .rootMenu--showInfo {
            height: 1em;
            width: 3em;
            margin: 0;
            padding: 0.3em;
        }

        .rootMenu--category {
            display: flex;
            align-items: center;
            min-height: 3em;
        }

        .subMenu {
            .backMenu {
                display: flex;
                align-items: center;
                border-bottom: 1px solid #bebebe;
            }
        }
    }
    /* Style the tab content */
    .tabContent {
        display: none;
        width: 100%;
        border-top: none;
        height: calc(100% - 4em - 1px);
        padding-top: 1em;

        &.visible {
            display: block;
        }
    }

    .clickable {
        cursor: pointer;
        border-radius: 0.1em;
        &:hover {
            background-color: var(
                --v-button-focus-background-color,
                $button-focus-background-color
            );
        }
    }
</style>
