import type { IEventMapper } from "../../vu-events/IEventMapper";
import { SupportedEvents } from "../../vu-events/SupportedEvents";
import { CustomEvents } from "./CustomEvents";

export const EventGenericMapping: IEventMapper = {
    [SupportedEvents.CanPlay]: {
        nativeEvents: [], // player
        videoEvents: ["canplay"], // videoElement
        customEvents: [],
    },
    [SupportedEvents.AudioTrackChange]: {
        nativeEvents: ["variantchanged"],
        videoEvents: [],
        customEvents: [CustomEvents.AudioTrackChange],
    },
    [SupportedEvents.AudioTrackAdded]: {
        nativeEvents: ["trackschanged"],
        videoEvents: [],
        customEvents: [],
    },
    [SupportedEvents.VideoTrackChange]: {
        nativeEvents: ["variantchanged"],
        videoEvents: [],
        customEvents: [],
    },
    [SupportedEvents.Ended]: {
        nativeEvents: [],
        videoEvents: ["ended"],
        customEvents: [],
    },
    [SupportedEvents.Error]: {
        nativeEvents: ["error"],
        videoEvents: ["error"],
        customEvents: ["error"],
    },
    [SupportedEvents.Pause]: {
        nativeEvents: [],
        videoEvents: ["pause"],
        customEvents: [],
    },
    [SupportedEvents.Play]: {
        nativeEvents: [],
        videoEvents: ["play"],
        customEvents: [],
    },
    [SupportedEvents.Playing]: {
        nativeEvents: [],
        videoEvents: ["playing"],
        customEvents: [],
    },
    [SupportedEvents.Progress]: {
        nativeEvents: [],
        videoEvents: ["progress"],
        customEvents: [],
    },
    [SupportedEvents.Seeked]: {
        nativeEvents: [],
        videoEvents: ["seeked"],
        customEvents: [],
    },
    [SupportedEvents.Seeking]: {
        nativeEvents: [],
        videoEvents: ["seeking"],
        customEvents: [],
    },
    [SupportedEvents.TimeUpdate]: {
        nativeEvents: [],
        videoEvents: ["timeupdate"],
        customEvents: [],
    },
    [SupportedEvents.VolumeChange]: {
        nativeEvents: [],
        videoEvents: ["volumechange"],
        customEvents: [],
    },
    [SupportedEvents.TextTrackChange]: {
        nativeEvents: ["trackschanged", "textchanged", "texttrackvisibility"],
        videoEvents: [],
        customEvents: [],
    },
    [SupportedEvents.Waiting]: {
        nativeEvents: [],
        videoEvents: ["waiting"],
        customEvents: [],
    },
    [SupportedEvents.StateChange]: {
        nativeEvents: [],
        videoEvents: [],
        customEvents: [CustomEvents.StateChange],
    },
    [SupportedEvents.SourceLoaded]: {
        nativeEvents: [],
        videoEvents: [],
        customEvents: [CustomEvents.SourceLoaded],
    },
};
