<script lang="ts">
    import { fade } from "svelte/transition";

    import { getContext } from "svelte";
    import type { Writable } from "svelte/store";
    import type { Seconds } from "../../../../../modules/vu-player-models/time/seconds";
    import type { State } from "../../types/state";
    let state: Writable<State> = getContext("state");

    import VchButton from "../shared/VchButton.svelte";

    import { overlayTransitionDuration } from "./../../utils/constants";

    $: showClips =
        !$state.isOverlayHidden &&
        !$state.isSettingsOpen &&
        $state.showClipControls;

    function skip(time: Seconds) {
        $state.adapter?.skipTo(time);
    }

    function skipFrameBackward() {
        skip(-1 * getFrameTime());
    }

    function skipFrameForward() {
        skip(getFrameTime());
    }

    function getFrameTime() {
        const playerFrameRate = $state.activeVideoTrack?.frameRate ?? NaN;
        const isPlayerFrameRateInvalid = isNaN(playerFrameRate);
        const frameRate = isPlayerFrameRateInvalid
            ? $state.defaultFrameRate
            : playerFrameRate;

        return 1 / frameRate;
    }
</script>

{#if showClips}
    <div
        class="vch-clip-controls"
        transition:fade={{ duration: overlayTransitionDuration }}
    >
        <div class="spacer" />
        <VchButton
            label="skip backward 30 seconds"
            on:click={() => {
                skip(-30);
            }}
        >
            -30s
        </VchButton>
        <VchButton
            label="skip backward 10 seconds (j)"
            on:click={() => {
                skip(-10);
            }}
        >
            -10s
        </VchButton>
        <VchButton
            label="skip backward 5 seconds"
            on:click={() => {
                skip(-5);
            }}
        >
            -5s
        </VchButton>
        <VchButton
            label="skip backward 1 second"
            on:click={() => {
                skip(-1);
            }}
        >
            -1s
        </VchButton>
        <VchButton label="skip backward 1 frame" on:click={skipFrameBackward}>
            -1f
        </VchButton>
        <VchButton label="skip forward 1 frame" on:click={skipFrameForward}>
            +1f
        </VchButton>
        <VchButton
            label="skip forward 1 second"
            on:click={() => {
                skip(1);
            }}
        >
            +1s
        </VchButton>
        <VchButton
            label="skip forward 5 seconds"
            on:click={() => {
                skip(5);
            }}
        >
            +5s
        </VchButton>
        <VchButton
            label="skip forward 10 seconds (l)"
            on:click={() => {
                skip(10);
            }}
        >
            +10s
        </VchButton>
        <VchButton
            label="skip forward 30 seconds"
            on:click={() => {
                skip(30);
            }}
        >
            +30s
        </VchButton>
        <div class="spacer" />
    </div>
{/if}

<style lang="scss">
    .vch-clip-controls {
        background-color: rgb(255 255 255 / 0.2);
        position: absolute;
        bottom: 5em;

        display: flex;

        width: 100%;
        height: 3em;

        .spacer {
            flex: 1;
        }
    }
</style>
