export enum SupportedEvents {
    CanPlay = "canplay",
    Ended = "ended",
    Error = "error",
    Pause = "pause",
    Play = "play",
    Playing = "playing",
    Progress = "progress",
    Seeked = "seeked",
    Seeking = "seeking",
    TimeUpdate = "timeupdate",
    VolumeChange = "volumechange",
    AudioTrackChange = "audiotrackchange",
    AudioTrackAdded = "audiotrackadded",
    TextTrackChange = "texttrackchange",
    VideoTrackChange = "videotrackchange",
    Waiting = "waiting",
    StateChange = "statechange",
    SourceLoaded = "sourceloaded",
}
