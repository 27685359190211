<script lang="ts">
    import { getContext, onMount } from "svelte";
    import type { Writable } from "svelte/store";
    import { getStreamType } from "../../../../../../../modules/vu-core/stream/get-stream-type";
    import type { State } from "../../../../types/state";
    import { prettyJson } from "../../../../utils/utils";
    let state: Writable<State> = getContext("state");

    import VchButton from "./../../../shared/VchButton.svelte";
    import VchIcon from "./../../../shared/VchIcon.svelte";

    let shakaStats = "Please click refresh to load stats";
    let selectedStream;

    $: sourceConfig = $state.sourceConfig;
    $: formattedSourceConfig = prettyJson($state.sourceConfig);

    $: playerConfig = $state.playerConfig;
    $: formattedPlayerConfig = prettyJson($state.playerConfig);

    function copyToClipboard(textToCopy: string) {
        navigator.clipboard
            .writeText(textToCopy ?? "N/A")
            .then(() => {
                console.log("Copied to the clipboard");
            })
            .catch(() => {
                console.warn("Failed to copy to the clipboard");
            });
    }

    onMount(() => {
        selectedStream = getStreamType($state.adapter.src);
    });

    function reloadStream() {
        if (!$state.sourceConfig) {
            return;
        }

        $state.adapter?.load($state.sourceConfig, () => {
            return selectedStream === "hls";
        });
    }

    function refreshStats() {
        let stats = $state.adapter.player.getStats();
        let json = JSON.stringify(stats, null, 4);
        shakaStats = json;
    }
</script>

<div class="vch-misc-menu">
    <div class="misc-item">
        <div class="misc-item__label">
            <label for="">Manifest:</label>
            <VchButton
                disabled={!sourceConfig}
                on:click={() => copyToClipboard($state.adapter?.src)}
                label="Copy manifest URL to the clipboard"
            >
                <VchIcon type="contentCopy" disabled={!sourceConfig} />
            </VchButton>
            <div class="load-stream-container">
                <select bind:value={selectedStream}>
                    {#if sourceConfig.dash}
                        <option value="dash">dash</option>
                    {/if}
                    {#if sourceConfig.hls}
                        <option value="hls">hls</option>
                    {/if}
                </select>
                <VchButton
                    disabled={!sourceConfig}
                    label="Reload manifest"
                    on:click={reloadStream}
                >
                    <VchIcon type="replay" disabled={!sourceConfig} />
                </VchButton>
            </div>
        </div>
    </div>

    <div class="misc-item">
        <div class="misc-item__label">
            <label for="">Source config:</label>
            <VchButton
                disabled={!sourceConfig}
                on:click={() => copyToClipboard(formattedSourceConfig)}
                label="Copy SourceConfig to the clipboard"
            >
                <VchIcon type="contentCopy" />
            </VchButton>
        </div>
        <div class="misc-item-content">
            <textarea readonly>{formattedSourceConfig}</textarea>
        </div>
    </div>
    <div class="misc-item">
        <div class="misc-item__label">
            <label for="">Player config:</label>
            <VchButton
                disabled={!playerConfig}
                on:click={() => copyToClipboard(formattedPlayerConfig)}
                label="Copy PlayerConfig to the clipboard"
            >
                <VchIcon type="contentCopy" disabled={!playerConfig} />
            </VchButton>
        </div>
        <div class="misc-item-content">
            <textarea readonly>
                {formattedPlayerConfig}
            </textarea>
        </div>
    </div>
    <div class="misc-item">
        <div class="misc-item__label">
            <label for="">Shaka stats: </label>
            <VchButton
                on:click={() => copyToClipboard(shakaStats)}
                label="Copy shaka stats"
            >
                <VchIcon type="contentCopy" />
            </VchButton>
            <VchButton on:click={refreshStats} label="Reload shaka stats">
                <VchIcon type="replay" />
            </VchButton>
        </div>
        <div class="misc-item-content">
            <textarea readonly>{shakaStats}</textarea>
        </div>
    </div>
</div>

<style lang="scss">
    .vch-misc-menu {
        textarea {
            margin: 0px;
            width: calc(100% - 2em);
            height: 209px;
            background-color: #ffffffcc;
            color: #1d1d1d !important;
            padding: 1em;
            text-shadow: 2px 2px 2px rgb(255 255 255 / 30%) !important;
        }

        .misc-item {
            padding-bottom: 0.3em;

            .misc-item__label {
                display: flex;
                align-content: center;
                align-items: center;
                height: 3em;
            }
            label {
                font-size: larger;
                display: inline-block;
            }
            .misc-item-content {
                display: flex;
                flex-wrap: wrap;
            }
        }

        .load-stream-container {
            display: flex;
            align-items: center;
            border: 1px solid #ffffffaa;
            border-radius: 3px;
            height: 3em;
            box-sizing: border-box;
            select {
                font-size: calc(10px + 0.4vw);
                height: 3em;
                padding: 0.7em;
                text-transform: uppercase;
                border: none;
                border-radius: 2px 0 2px 0;
                margin: 0;
            }
        }
    }
</style>
