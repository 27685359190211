import type { IEventBus } from "./IEventBus";
export function CustomEventBus(): IEventBus {
    const subscriptions = {};

    return {
        on(eventType: string, callback: Function, once = false) {
            const key = Symbol("key");

            if (!subscriptions[eventType]) {
                subscriptions[eventType] = {};
            }
            subscriptions[eventType][key] = [callback, once];

            return {
                off() {
                    delete subscriptions[eventType][key];
                },
            };
        },
        emit(eventType: string, arg?: unknown) {
            if (!subscriptions[eventType]) return;

            Object.getOwnPropertySymbols(subscriptions[eventType]).forEach(
                (key) => {
                    const [handler, once] = subscriptions[eventType][key];
                    handler(arg);
                    if (once) {
                        delete subscriptions[eventType][key];
                    }
                }
            );
        },
    };
}
