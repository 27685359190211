<script lang="ts">
    import { getContext } from "svelte";
    import { fade } from "svelte/transition";

    import type { Writable } from "svelte/store";
    import type { State } from "../../types/state";
    let state: Writable<State> = getContext("state");

    import { overlayTransitionDuration } from "../../utils/constants";

    let min = 0;
    let max = 100;
    let step = 0.01;
    let label = "Progress bar";
    let value = 0;

    $: percentageLabel = `${(value / max) * 100}%`;
    $: {
        let currentTime = $state?.currentTime ?? NaN;
        let duration = $state.isLive
            ? $state?.dvr ?? NaN
            : $state?.duration ?? NaN;
        value =
            isNaN(currentTime) || isNaN(duration)
                ? 0
                : (currentTime * 100) / duration;
    }
    $: showOverlay = !$state.isOverlayHidden || $state.isSettingsOpen;
    $: disabled = !$state.hasCanplayFired || $state.playerState === "ended";

    function onValueChange(event: Event) {
        const percentage = parseFloat(
            (event.target as HTMLInputElement)?.value
        );

        if (isNaN(percentage)) {
            return;
        }

        $state.adapter?.seekPercent(percentage);
    }
</script>

{#if showOverlay}
    <div
        class="vch-slider"
        transition:fade={{ duration: overlayTransitionDuration }}
    >
        <input
            {disabled}
            type="range"
            {step}
            {min}
            {max}
            {value}
            autocomplete="off"
            aria-label={label}
            aria-valuemin={min}
            aria-valuemax={max}
            aria-valuenow={value}
            aria-valuetext={percentageLabel}
            aria-orientation="horizontal"
            on:input={onValueChange}
        />
    </div>
{/if}

<style lang="scss">
    @import "../../globals/mixins.scss";

    .vch-slider {
        width: 100%;
        height: 1em;
        position: absolute;
        bottom: 3.3em;

        @mixin slider-thumb() {
            background: var(--v-slider-thumb-color, white);
            border-radius: 0.1em;

            width: 3em;
            height: 1em;
        }

        input[type="range"] {
            -webkit-appearance: none;
            overflow: hidden;

            background-color: rgba($slider-color, 0.3);

            margin: 0;
            position: absolute;
            top: 0;
            width: 100%;
            height: 1em;
            border-radius: 0.1em;

            cursor: ew-resize;

            &:disabled {
                cursor: not-allowed;
            }

            &::-webkit-slider-thumb {
                @include slider-thumb();

                -webkit-appearance: none;
                box-shadow: -800px 0 0 800px rgba($slider-color, 1);
            }

            &::-webkit-slider-runnable-track {
                -webkit-appearance: none;
                height: 1em;
                color: rgb(175, 175, 175);
            }

            &::-moz-range-track {
                height: 1em;
            }

            &::-moz-range-progress {
                background-color: rgba($slider-color, 1);
                height: 1em;
            }

            &::-moz-range-thumb {
                @include slider-thumb();
                border: none;
            }
        }
    }
</style>
