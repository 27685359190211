import { getExtension } from "./get-extension";

export function getStreamType(uri?: string): StreamType {
    let extension = getExtension(uri);

    if (extension === "mpd") {
        return "dash";
    }
    if (extension === "m3u8") {
        return "hls";
    }

    return "n/a";
}

export type StreamType = "dash" | "hls" | "n/a";
