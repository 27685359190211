<script lang="ts">
    export let type = "play";
    export let disabled = false;
</script>

{#if type === "settings"}
    <svg
        class:disabled
        version="1.1"
        width="100%"
        height="100%"
        focusable="false"
        viewBox="0 0 36 36">
        <path
            d="m 23.94,18.78 c .03,-0.25 .05,-0.51 .05,-0.78 0,-0.27 -0.02,-0.52 -0.05,-0.78 l 1.68,-1.32 c .15,-0.12 .19,-0.33 .09,-0.51 l -1.6,-2.76 c -0.09,-0.17 -0.31,-0.24 -0.48,-0.17 l -1.99,.8 c -0.41,-0.32 -0.86,-0.58 -1.35,-0.78 l -0.30,-2.12 c -0.02,-0.19 -0.19,-0.33 -0.39,-0.33 l -3.2,0 c -0.2,0 -0.36,.14 -0.39,.33 l -0.30,2.12 c -0.48,.2 -0.93,.47 -1.35,.78 l -1.99,-0.8 c -0.18,-0.07 -0.39,0 -0.48,.17 l -1.6,2.76 c -0.10,.17 -0.05,.39 .09,.51 l 1.68,1.32 c -0.03,.25 -0.05,.52 -0.05,.78 0,.26 .02,.52 .05,.78 l -1.68,1.32 c -0.15,.12 -0.19,.33 -0.09,.51 l 1.6,2.76 c .09,.17 .31,.24 .48,.17 l 1.99,-0.8 c .41,.32 .86,.58 1.35,.78 l .30,2.12 c .02,.19 .19,.33 .39,.33 l 3.2,0 c .2,0 .36,-0.14 .39,-0.33 l .30,-2.12 c .48,-0.2 .93,-0.47 1.35,-0.78 l 1.99,.8 c .18,.07 .39,0 .48,-0.17 l 1.6,-2.76 c .09,-0.17 .05,-0.39 -0.09,-0.51 l -1.68,-1.32 0,0 z m -5.94,2.01 c -1.54,0 -2.8,-1.25 -2.8,-2.8 0,-1.54 1.25,-2.8 2.8,-2.8 1.54,0 2.8,1.25 2.8,2.8 0,1.54 -1.25,2.8 -2.8,2.8 l 0,0 z"
        />
    </svg>
{:else if type === "captions"}
    <svg
        class:disabled
        version="1.1"
        width="100%"
        height="100%"
        focusable="false"
        viewBox="0 0 36 36">
        <path
            d="M11,11 C9.9,11 9,11.9 9,13 L9,23 C9,24.1 9.9,25 11,25 L25,25 C26.1,25 27,24.1 27,23 L27,13 C27,11.9 26.1,11 25,11 L11,11 Z M11,17 L14,17 L14,19 L11,19 L11,17 L11,17 Z M20,23 L11,23 L11,21 L20,21 L20,23 L20,23 Z M25,23 L22,23 L22,21 L25,21 L25,23 L25,23 Z M25,19 L16,19 L16,17 L25,17 L25,19 L25,19 Z"
        />
    </svg>
{:else if type === "pip"}
    <svg
        class:disabled
        version="1.1"
        width="100%"
        height="100%"
        focusable="false"
        viewBox="0 0 36 36">
        <path
            d="M25,17 L17,17 L17,23 L25,23 L25,17 L25,17 Z M29,25 L29,10.98 C29,9.88 28.1,9 27,9 L9,9 C7.9,9 7,9.88 7,10.98 L7,25 C7,26.1 7.9,27 9,27 L27,27 C28.1,27 29,26.1 29,25 L29,25 Z M27,25.02 L9,25.02 L9,10.97 L27,10.97 L27,25.02 L27,25.02 Z"
        />
    </svg>
{:else if type === "chromeCast"}
    <svg
        class:disabled
        version="1.1"
        width="100%"
        height="100%"
        focusable="false"
        viewBox="0 0 36 36">
        <path
            d="M27,9 L9,9 C7.9,9 7,9.9 7,11 L7,14 L9,14 L9,11 L27,11 L27,25 L20,25 L20,27 L27,27 C28.1,27 29,26.1 29,25 L29,11 C29,9.9 28.1,9 27,9 L27,9 Z M7,24 L7,27 L10,27 C10,25.34 8.66,24 7,24 L7,24 Z M7,20 L7,22 C9.76,22 12,24.24 12,27 L14,27 C14,23.13 10.87,20 7,20 L7,20 Z M7,16 L7,18 C11.97,18 16,22.03 16,27 L18,27 C18,20.92 13.07,16 7,16 L7,16 Z"
        />
    </svg>
{:else if type === "fullScreenEnter"}
    <svg
        class:disabled
        version="1.1"
        width="100%"
        height="100%"
        focusable="false"
        viewBox="0 0 36 36">
        <path d="m 10,16 2,0 0,-4 4,0 0,-2 L 10,10 l 0,6 0,0 z" />
        <path d="m 20,10 0,2 4,0 0,4 2,0 L 26,10 l -6,0 0,0 z" />
        <path d="m 24,24 -4,0 0,2 L 26,26 l 0,-6 -2,0 0,4 0,0 z" />
        <path d="M 12,20 10,20 10,26 l 6,0 0,-2 -4,0 0,-4 0,0 z" />
    </svg>
{:else if type === "fullScreenExit"}
    <svg
        class:disabled
        version="1.1"
        width="100%"
        height="100%"
        focusable="false"
        viewBox="0 0 36 36">
        <path d="m 14,14 -4,0 0,2 6,0 0,-6 -2,0 0,4 0,0 z" />
        <path d="m 22,14 0,-4 -2,0 0,6 6,0 0,-2 -4,0 0,0 z" />
        <path d="m 20,26 2,0 0,-4 4,0 0,-2 -6,0 0,6 0,0 z" />
        <path d="m 10,22 4,0 0,4 2,0 0,-6 -6,0 0,2 0,0 z" />
    </svg>
{:else if type === "muted"}
    <svg
        class:disabled
        version="1.1"
        height="100%"
        focusable="false"
        viewBox="0 0 36 36">
        <path
            d="M8,21 L12,21 L17,26 L17,10 L12,15 L8,15 L8,21 Z M19,14 L19,22 C20.48,21.32 21.5,19.77 21.5,18 C21.5,16.26 20.48,14.74 19,14 ZM19,11.29 C21.89,12.15 24,14.83 24,18 C24,21.17 21.89,23.85 19,24.71 L19,26.77 C23.01,25.86 26,22.28 26,18 C26,13.72 23.01,10.14 19,9.23 L19,11.29 Z"
        />
        <path d="M 9.25,9 7.98,10.27 24.71,27 l 1.27,-1.27 Z" />
    </svg>
{:else if type === "unmuted"}
    <svg
        class:disabled
        version="1.1"
        height="100%"
        focusable="false"
        viewBox="0 0 36 36">
        <path
            d="M8,21 L12,21 L17,26 L17,10 L12,15 L8,15 L8,21 Z M19,14 L19,22 C20.48,21.32 21.5,19.77 21.5,18 C21.5,16.26 20.48,14.74 19,14 ZM19,11.29 C21.89,12.15 24,14.83 24,18 C24,21.17 21.89,23.85 19,24.71 L19,26.77 C23.01,25.86 26,22.28 26,18 C26,13.72 23.01,10.14 19,9.23 L19,11.29 Z"
        />
    </svg>
{:else if type === "play"}
    <svg
        class:disabled
        version="1.1"
        width="100%"
        height="100%"
        focusable="false"
        viewBox="0 0 36 36">
        <path
            d="M 12,26 18.5,22 18.5,14 12,10 z M 18.5,22 25,18 25,18 18.5,14 z"
        />
    </svg>
{:else if type === "pause"}
    <svg
        class:disabled
        version="1.1"
        width="100%"
        height="100%"
        focusable="false"
        viewBox="0 0 36 36">
        <path d="M 12,26 16,26 16,10 12,10 z M 21,26 25,26 25,10 21,10 z" />
    </svg>
{:else if type === "videoQuality"}
    <svg
        class:disabled
        height="100%"
        width="100%"
        focusable="false"
        viewBox="-6 -6 36 36">
        <g>
            <rect fill="none" height="24" width="24" />
        </g>
        <g>
            <g>
                <path
                    d="M3,6h18v5h2V6c0-1.1-0.9-2-2-2H3C1.9,4,1,4.9,1,6v12c0,1.1,0.9,2,2,2h9v-2H3V6z"
                />
                <polygon points="15,12 9,8 9,16" />
                <path
                    d="M22.71,18.43c0.03-0.29,0.04-0.58,0.01-0.86l1.07-0.85c0.1-0.08,0.12-0.21,0.06-0.32l-1.03-1.79 c-0.06-0.11-0.19-0.15-0.31-0.11L21.23,15c-0.23-0.17-0.48-0.31-0.75-0.42l-0.2-1.36C20.26,13.09,20.16,13,20.03,13h-2.07 c-0.12,0-0.23,0.09-0.25,0.21l-0.2,1.36c-0.26,0.11-0.51,0.26-0.74,0.42l-1.28-0.5c-0.12-0.05-0.25,0-0.31,0.11l-1.03,1.79 c-0.06,0.11-0.04,0.24,0.06,0.32l1.07,0.86c-0.03,0.29-0.04,0.58-0.01,0.86l-1.07,0.85c-0.1,0.08-0.12,0.21-0.06,0.32l1.03,1.79 c0.06,0.11,0.19,0.15,0.31,0.11l1.27-0.5c0.23,0.17,0.48,0.31,0.75,0.42l0.2,1.36c0.02,0.12,0.12,0.21,0.25,0.21h2.07 c0.12,0,0.23-0.09,0.25-0.21l0.2-1.36c0.26-0.11,0.51-0.26,0.74-0.42l1.28,0.5c0.12,0.05,0.25,0,0.31-0.11l1.03-1.79 c0.06-0.11,0.04-0.24-0.06-0.32L22.71,18.43z M19,19.5c-0.83,0-1.5-0.67-1.5-1.5s0.67-1.5,1.5-1.5s1.5,0.67,1.5,1.5 S19.83,19.5,19,19.5z"
                />
            </g>
        </g>
    </svg>
{:else if type === "audioTrack"}
    <svg
        class:disabled
        xmlns="http://www.w3.org/2000/svg"
        height="100%"
        width="100%"
        focusable="false"
        viewBox="-6 -6 36 36">
        <path d="M0 0h24v24H0z" fill="none" />
        <path
            d="M12 3v9.28c-.47-.17-.97-.28-1.5-.28C8.01 12 6 14.01 6 16.5S8.01 21 10.5 21c2.31 0 4.2-1.75 4.45-4H15V6h4V3h-7z"
        />
    </svg>
{:else if type === "more"}
    <svg
        class:disabled
        xmlns="http://www.w3.org/2000/svg"
        height="100%"
        width="100%"
        focusable="false"
        viewBox="-6 -6 36 36">
        <path d="M0 0h24v24H0z" fill="none" />
        <path
            d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
        />
    </svg>
{:else if type === "speaker"}
    <svg
        class:disabled
        xmlns="http://www.w3.org/2000/svg"
        height="100%"
        width="100%"
        focusable="false"
        viewBox="-6 -6 36 36">
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M7 9v6h4l5 5V4l-5 5H7z" />
    </svg>
{:else if type === "featurePlayList"}
    <svg
        class:disabled
        xmlns="http://www.w3.org/2000/svg"
        height="100%"
        width="100%"
        focusable="false"
        viewBox="-6 -6 36 36">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
            d="M21 3H3c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-9 8H3V9h9v2zm0-4H3V5h9v2z"
        />
    </svg>
{:else if type === "miscellaneous"}
    <svg
        class:disabled
        xmlns="http://www.w3.org/2000/svg"
        enable-background="new 0 0 24 24"
        height="100%"
        width="100%"
        focusable="false"
        viewBox="-6 -6 36 36">
        <g>
            <rect fill="none" height="24" width="24" />
        </g>
        <g>
            <g>
                <path
                    d="M14.17,13.71l1.4-2.42c0.09-0.15,0.05-0.34-0.08-0.45l-1.48-1.16c0.03-0.22,0.05-0.45,0.05-0.68s-0.02-0.46-0.05-0.69 l1.48-1.16c0.13-0.11,0.17-0.3,0.08-0.45l-1.4-2.42c-0.09-0.15-0.27-0.21-0.43-0.15L12,4.83c-0.36-0.28-0.75-0.51-1.18-0.69 l-0.26-1.85C10.53,2.13,10.38,2,10.21,2h-2.8C7.24,2,7.09,2.13,7.06,2.3L6.8,4.15C6.38,4.33,5.98,4.56,5.62,4.84l-1.74-0.7 c-0.16-0.06-0.34,0-0.43,0.15l-1.4,2.42C1.96,6.86,2,7.05,2.13,7.16l1.48,1.16C3.58,8.54,3.56,8.77,3.56,9s0.02,0.46,0.05,0.69 l-1.48,1.16C2,10.96,1.96,11.15,2.05,11.3l1.4,2.42c0.09,0.15,0.27,0.21,0.43,0.15l1.74-0.7c0.36,0.28,0.75,0.51,1.18,0.69 l0.26,1.85C7.09,15.87,7.24,16,7.41,16h2.8c0.17,0,0.32-0.13,0.35-0.3l0.26-1.85c0.42-0.18,0.82-0.41,1.18-0.69l1.74,0.7 C13.9,13.92,14.08,13.86,14.17,13.71z M8.81,11c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2s2,0.9,2,2C10.81,10.1,9.91,11,8.81,11z"
                />
                <path
                    d="M21.92,18.67l-0.96-0.74c0.02-0.14,0.04-0.29,0.04-0.44c0-0.15-0.01-0.3-0.04-0.44l0.95-0.74 c0.08-0.07,0.11-0.19,0.05-0.29l-0.9-1.55c-0.05-0.1-0.17-0.13-0.28-0.1l-1.11,0.45c-0.23-0.18-0.48-0.33-0.76-0.44l-0.17-1.18 C18.73,13.08,18.63,13,18.53,13h-1.79c-0.11,0-0.21,0.08-0.22,0.19l-0.17,1.18c-0.27,0.12-0.53,0.26-0.76,0.44l-1.11-0.45 c-0.1-0.04-0.22,0-0.28,0.1l-0.9,1.55c-0.05,0.1-0.04,0.22,0.05,0.29l0.95,0.74c-0.02,0.14-0.03,0.29-0.03,0.44 c0,0.15,0.01,0.3,0.03,0.44l-0.95,0.74c-0.08,0.07-0.11,0.19-0.05,0.29l0.9,1.55c0.05,0.1,0.17,0.13,0.28,0.1l1.11-0.45 c0.23,0.18,0.48,0.33,0.76,0.44l0.17,1.18c0.02,0.11,0.11,0.19,0.22,0.19h1.79c0.11,0,0.21-0.08,0.22-0.19l0.17-1.18 c0.27-0.12,0.53-0.26,0.75-0.44l1.12,0.45c0.1,0.04,0.22,0,0.28-0.1l0.9-1.55C22.03,18.86,22,18.74,21.92,18.67z M17.63,18.83 c-0.74,0-1.35-0.6-1.35-1.35s0.6-1.35,1.35-1.35s1.35,0.6,1.35,1.35S18.37,18.83,17.63,18.83z"
                />
            </g>
        </g>
    </svg>
{:else if type === "replay"}
    <svg
        class:disabled
        xmlns="http://www.w3.org/2000/svg"
        height="100%"
        width="100%"
        focusable="false"
        viewBox="-6 -6 36 36">
        <path d="M0 0h24v24H0z" fill="none" />
        <path
            d="M12 5V1L7 6l5 5V7c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6H4c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8z"
        />
    </svg>
{:else if type === "contentCopy"}
    <svg
        class:disabled
        xmlns="http://www.w3.org/2000/svg"
        height="100%"
        width="100%"
        focusable="false"
        viewBox="-6 -6 36 36">
        <path d="M0 0h24v24H0z" fill="none" />
        <path
            d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"
        />
    </svg>
{:else if type === "cross"}
    <svg
        class:disabled
        xmlns="http://www.w3.org/2000/svg"
        height="100%"
        width="100%"
        focusable="false"
        viewBox="-6 -6 36 36">
        <path d="M0 0h24v24H0z" fill="none" />
        <path
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
        />
    </svg>
{:else if type === "caretLeft"}
    <svg
        class:disabled
        xmlns="http://www.w3.org/2000/svg"
        height="100%"
        width="100%"
        focusable="false"
        viewBox="-6 -6 36 36">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z" />
    </svg>
{:else if type === "errorOutline"}
    <svg
        class:disabled
        xmlns="http://www.w3.org/2000/svg"
        height="100%"
        width="100%"
        focusable="false"
        viewBox="-6 -6 36 36">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
            d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
        />
    </svg>
{:else if type === "pin"}
    <svg
        class:disabled
        xmlns="http://www.w3.org/2000/svg"
        height="100%"
        width="100%"
        focusable="false"
        viewBox="-6 -6 36 36">
        <g>
            <rect fill="none" height="24" width="24" />
        </g>
        <g>
            <path
                d="M16,9V4l1,0c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H7C6.45,2,6,2.45,6,3v0 c0,0.55,0.45,1,1,1l1,0v5c0,1.66-1.34,3-3,3h0v2h5.97v7l1,1l1-1v-7H19v-2h0C17.34,12,16,10.66,16,9z"
                fill-rule="evenodd"
            />
        </g>
    </svg>
{/if}

<style type="text/scss" lang="scss">
    svg {
        fill: #fff;
        pointer-events: none;
        filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));

        &.disabled {
            fill: #bbb;
        }
    }
</style>
