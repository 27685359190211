import type { IInstanceProvider } from "../../vu-player-models/configuration/instance-provider";
import type { IVolumeService } from "../../vu-player-models/services/IVolumeService";

export class VolumeService implements IVolumeService {
    constructor(
        private readonly _instance: IInstanceProvider,
        private readonly min: number = 0,
        private readonly max: number = 1
    ) {}

    public get video(): HTMLVideoElement {
        return this._instance().getMediaElement();
    }

    public get volume(): number {
        return this.video.volume;
    }

    public set volume(value: number) {
        this.video.volume = Math.min(Math.max(this.min, value), this.max);
        this.video.muted = value <= 0;
    }

    public get isMuted(): boolean {
        return this.video.muted;
    }

    public async mute(): Promise<void> {
        if (!this.video.muted) {
            this.video.muted = true;
        }
        return Promise.resolve();
    }

    public async unmute(): Promise<void> {
        if (this.video.muted) {
            this.video.muted = false;
        }
        return Promise.resolve();
    }
}
