<script lang="ts">
    import { getContext } from "svelte";
    import VchButton from "../shared/VchButton.svelte";
    import VchIcon from "../shared/VchIcon.svelte";
    import type { Writable } from "svelte/store";
    import type { State } from "../../types/state";
    import type { IVuPlayer } from "../../../../../modules/vu-player-models/player/IVuPlayer";

    let state: Writable<State> = getContext("state");

    $: disabled = !$state.hasCanplayFired;
    $: type = $state.isMuted ? "muted" : "unmuted";
    $: label = $state.isMuted ? "unmute (m)" : "mute (m)";

    function onClick() {
        $state.isMuted ? $state.adapter?.unmute() : $state.adapter?.mute();
    }
    function updateVolume(event) {
        ($state.adapter as IVuPlayer).volume = Number(
            event.currentTarget.value
        );
    }
</script>

<VchButton on:click={onClick} {disabled} {label}>
    <VchIcon {type} {disabled} />
</VchButton>

{#if $state.playerWidth > 400}
    <div class="volume-container">
        <input
            type="range"
            min="0"
            max="1"
            step="0.05"
            value={$state.volume}
            on:change={updateVolume}
            on:input={updateVolume}
        />
    </div>
{/if}

<style lang="scss">
    @import "../../globals/mixins.scss";

    .volume-container {
        width: 5em;
        height: 3em;
        left: 3.5em;
        display: flex;
        align-items: center;
        padding: 0 0.5em;

        input[type="range"] {
            -webkit-appearance: none;
            width: 5em;
            height: 1em;
            overflow: hidden;
            background-color: rgba($slider-color, 0.3);
            margin: 0;
            border-radius: 0.1em;

            &::-webkit-slider-thumb {
                -webkit-appearance: none;
                cursor: ew-resize;
                width: 1em;
                height: 1em;
                background: var(--v-slider-thumb-color, white);
                box-shadow: -800px 0 0 800px rgba($slider-color, 1);
                border-radius: 0.1em;
            }

            &::-webkit-slider-runnable-track {
                -webkit-appearance: none;
                height: 1em;
                color: rgb(175, 175, 175);
            }

            &::-moz-range-track {
                height: 1em;
            }

            &::-moz-range-progress {
                background-color: rgba($slider-color, 1);
                height: 1em;
            }

            &::-moz-range-thumb {
                background: var(--v-slider-thumb-color, white);
                border: none;
                border-radius: 0;
                cursor: ew-resize;
                border-radius: 0.1em;
            }
        }
    }
</style>
