import { isNullOrUndefined } from "../validation/is-null-or-undefined";

export type FullScreenableElement = {
    requestFullscreen?: Function;
    mozRequestFullScreen: Function;
    webkitRequestFullscreen: Function;
    webkitEnterFullscreen: Function;
    msRequestFullscreen: Function;
};

export function enterFullScreen(element: FullScreenableElement): void {
    if (element.requestFullscreen) {
        element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
    } else if (element.webkitEnterFullscreen) {
        element.webkitEnterFullscreen();
    } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
    }
}

export function exitFullScreen(anyDocument: Document | any = document): void {
    if (anyDocument.exitFullscreen) {
        anyDocument.exitFullscreen();
    } else if (anyDocument.webkitExitFullscreen) {
        anyDocument.webkitExitFullscreen();
    } else if (anyDocument.mozCancelFullScreen) {
        anyDocument.mozCancelFullScreen();
    } else if (anyDocument.msExitFullscreen) {
        anyDocument.msExitFullscreen();
    }
}

export function isFullscreen(element?: FullScreenableElement): boolean {
    let anyDocument: any = document;
    let fullscreenElement =
        anyDocument.fullscreenElement ??
        anyDocument.mozFullScreenElement ??
        anyDocument.msFullscreenElement ??
        anyDocument.webkitFullscreenElement ??
        anyDocument.webkitCurrentFullScreenElement;

    if (isNullOrUndefined(element)) {
        return !!fullscreenElement;
    }

    return fullscreenElement === element;
}

export function toggleFullscreen(
    container: unknown,
    isFullScreen = isFullscreen()
): void {
    if (isFullScreen) {
        exitFullScreen();
    } else {
        enterFullScreen(container as any);
    }
}
