import { EventGenericMapping } from "../../../modules/shaka-3/events/EventGenericMapping";
import { NativeEventSubscriber } from "../../../modules/shaka-3/events/NativeEventSubscriber";
import { PlayerInstanceFactory } from "../../../modules/shaka-3/PlayerInstanceFactory";
import { AudioTrackService } from "../../../modules/shaka-3/services/AudioTrackService";
import { LiveTimeService } from "../../../modules/shaka-3/services/LiveTimeService";
import { PlaybackService } from "../../../modules/shaka-3/services/PlaybackService";
import { StateService } from "../../../modules/shaka-3/services/StateService";
import { TextTrackService } from "../../../modules/shaka-3/services/TextTrackService";
import { VideoTrackService } from "../../../modules/shaka-3/services/VideoTrackService";
import { VodTimeService } from "../../../modules/shaka-3/services/VodTimeService";
import { VolumeService } from "../../../modules/shaka-3/services/VolumeService";
import { ShakaAdapter } from "../../../modules/shaka-3/ShakaAdapter";
import { SourceLoader } from "../../../modules/shaka-3/SourceLoader";
import { CustomEventBus } from "../../../modules/vu-events/CustomEventBus";
import { EventGenericSubscriber } from "../../../modules/vu-events/EventGenericSubscriber";
import { VideoElementEventSubscriber } from "../../../modules/vu-events/VideoElementEventSubscriber";
export function PlayerAdapter(playerConfig, isLive = false) {
    const [shakaPlayer, videoElement] = PlayerInstanceFactory(playerConfig);
    // TODO: override isLive    shakaPlayer.isLive();
    const instanceProvider = () => shakaPlayer;
    const customEventBus = CustomEventBus();
    const sourceLoader = new SourceLoader(instanceProvider, customEventBus);
    const volumeService = new VolumeService(instanceProvider);
    const playbackService = new PlaybackService(instanceProvider, isLive);
    const audioTrackService = new AudioTrackService(instanceProvider, customEventBus);
    const videoTrackService = new VideoTrackService(instanceProvider);
    const textTrackService = new TextTrackService(instanceProvider);
    const timeService = isLive
        ? new LiveTimeService(instanceProvider)
        : new VodTimeService(instanceProvider);
    const genericEventSubscriber = EventGenericSubscriber(EventGenericMapping, NativeEventSubscriber(instanceProvider), VideoElementEventSubscriber(videoElement), customEventBus);
    const stateService = new StateService(instanceProvider, genericEventSubscriber, customEventBus);
    return new ShakaAdapter(instanceProvider, sourceLoader, genericEventSubscriber, volumeService, playbackService, audioTrackService, videoTrackService, textTrackService, timeService, stateService);
}
