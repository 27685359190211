import { assertDefined } from "../vu-core/validation/assert";
import type { IEventSubscriber } from "../vu-events/IEventSubscriber";
import type { IEventUnsubscriber } from "../vu-events/IEventUnsubscriber";
import type { SupportedEvents } from "../vu-events/SupportedEvents";
import type { IInstanceProvider } from "../vu-player-models/configuration/instance-provider";
import type { SourceConfig } from "../vu-player-models/configuration/source-config";
import type { ISourceLoader } from "../vu-player-models/player/ISourceLoader";
import type { IVuPlayer } from "../vu-player-models/player/IVuPlayer";
import type { PlayerState } from "../vu-player-models/player/PlayerState";
import type { IAudioTrackService } from "../vu-player-models/services/IAudioTrackService";
import type { IPlaybackService } from "../vu-player-models/services/IPlaybackService";
import type { IStateService } from "../vu-player-models/services/IStateService";
import type { ITextTrackService } from "../vu-player-models/services/ITextTrackService";
import type { ITimeService } from "../vu-player-models/services/ITimeService";
import type { IVideoTrackService } from "../vu-player-models/services/IVideoTrackService";
import type { IVolumeService } from "../vu-player-models/services/IVolumeService";
import type { Seconds } from "../vu-player-models/time/seconds";
import type { SeekableRange } from "../vu-player-models/time/seekable-range";
import type { AudioTrack } from "../vu-player-models/tracks/audio-track";
import type { VideoTrack } from "../vu-player-models/tracks/video-track";
import type { TextTrack } from "../vu-player-models/tracks/text-track";
import type { Predicate } from "../vu-core/types/predicate";

export class ShakaAdapter implements IVuPlayer {
    constructor(
        public playerProvider: IInstanceProvider,
        private readonly sourceLoader: ISourceLoader,
        private readonly genericEventSubscriber: IEventSubscriber,
        private readonly volumeService: IVolumeService,
        private readonly playbackService: IPlaybackService,
        private readonly audioTrackService: IAudioTrackService,
        private readonly videoTrackService: IVideoTrackService,
        private readonly textTrackService: ITextTrackService,
        private readonly timeService: ITimeService,
        private readonly stateService: IStateService
    ) {
        assertDefined(sourceLoader, "sourceLoader");
        assertDefined(genericEventSubscriber, "genericEventSubscriber");
        assertDefined(volumeService, "volumeService");
        assertDefined(playbackService, "playbackService");
        assertDefined(audioTrackService, "audioTrackService");
        assertDefined(videoTrackService, "videoTrackService");
        assertDefined(textTrackService, "textTrackService");
        assertDefined(timeService, "timeService");
        assertDefined(stateService, "stateService");
    }

    setTextTrack(textTrack: TextTrack): Promise<void> {
        return this.textTrackService.setTextTrack(textTrack);
    }
    setVideoTrack(videoTrack: VideoTrack): Promise<void> {
        return this.videoTrackService.setVideoTrack(videoTrack);
    }
    setAudioTrack(audioTrack: AudioTrack): Promise<void> {
        return this.audioTrackService.setAudioTrack(audioTrack);
    }

    load(
        playerSource: SourceConfig,
        chooseHls?: Predicate<void>
    ): Promise<void> {
        return this.sourceLoader.load(playerSource, chooseHls);
    }

    on(
        eventName: SupportedEvents,
        eventListener: EventListener,
        once = false
    ): IEventUnsubscriber {
        return this.genericEventSubscriber.on(eventName, eventListener, once);
    }

    mute(): Promise<void> {
        return this.volumeService.mute();
    }
    unmute(): Promise<void> {
        return this.volumeService.unmute();
    }
    play(): Promise<void> {
        return this.playbackService.play();
    }
    pause(): Promise<void> {
        return this.playbackService.pause();
    }
    seek(seconds: Seconds): Promise<void> {
        return this.playbackService.seek(seconds);
    }
    seekPercent(percent: number): Promise<void> {
        return this.playbackService.seekPercent(percent);
    }
    seekToDate(when: Date): Promise<void> {
        return this.playbackService.seekToDate(when);
    }
    skipTo(seconds: Seconds): Promise<void> {
        return this.playbackService.skipTo(seconds);
    }
    goToLive(): Promise<void> {
        return this.playbackService.goToLive();
    }
    setAbr(enable: boolean): Promise<void> {
        return this.videoTrackService.setAbr(enable);
    }

    get videoElement(): HTMLVideoElement | null {
        return this.player.getMediaElement();
    }
    get player(): any {
        return this.playerProvider();
    }
    get isMuted(): boolean {
        return this.volumeService.isMuted;
    }
    get volume(): number {
        return this.volumeService.volume;
    }
    set volume(value: number) {
        this.volumeService.volume = value;
    }
    get isPaused() {
        return this.playbackService.isPaused;
    }
    get audioTracks(): AudioTrack[] {
        return this.audioTrackService.audioTracks;
    }
    get activeAudioTrack(): AudioTrack {
        return this.audioTrackService.activeAudioTrack;
    }
    get videoTracks(): VideoTrack[] {
        return this.videoTrackService.videoTracks;
    }
    get activeVideoTrack(): VideoTrack {
        return this.videoTrackService.activeVideoTrack;
    }
    get textTracks(): TextTrack[] {
        return this.textTrackService.textTracks;
    }
    get activeTextTrack(): TextTrack {
        return this.textTrackService.activeTextTrack;
    }
    get currentTime(): Seconds {
        return this.timeService.currentTime;
    }
    get currentUTC(): Date {
        return this.timeService.currentUTC;
    }
    get duration(): Seconds {
        return this.timeService.duration;
    }
    get dvr(): Seconds {
        return this.timeService.dvr;
    }
    get seekableRange(): SeekableRange {
        return this.timeService.seekableRange;
    }
    get state(): PlayerState {
        return this.stateService.state;
    }
    get isLive(): boolean {
        return this.playbackService.isLive;
    }
    get video(): HTMLVideoElement {
        return this.playerProvider().getMediaElement();
    }
    get src(): string {
        return this.sourceLoader.src;
    }
    get sourceConfig(): SourceConfig {
        return this.sourceLoader.sourceConfig;
    }

    get abr(): boolean {
        return this.videoTrackService.abr;
    }
}
