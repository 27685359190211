<script lang="ts">
    import VchButton from "../shared/VchButton.svelte";
    import VchIcon from "../shared/VchIcon.svelte";

    import { getContext } from "svelte";
    import type { Writable } from "svelte/store";
    import type { State } from "../../types/state";
    let state: Writable<State> = getContext("state");

    $: isOpen = $state.isSettingsOpen;

    function onClick() {
        $state.isSettingsOpen = !$state.isSettingsOpen;
    }
</script>

<VchButton on:click={onClick} label="open/close settings">
    <div class="rotate-icon" class:isOpen>
        <VchIcon type="more" />
    </div>
</VchButton>

<style lang="scss">
    .rotate-icon {
        &.isOpen {
            transform: rotate(90deg);
        }
        transition: all 0.4s ease-in-out;
    }
</style>
