<script lang="ts">
    import { capitalize } from "../../../../../modules/vu-core/string/capitalize";

    export let label;
    export let disabled = false;
    export let wide = false;

    $: formattedLabel = capitalize(label);
</script>

<button
    on:click
    type="button"
    title={formattedLabel}
    aria-label={formattedLabel}
    aria-disabled={disabled}
    class:wide
    {disabled}
>
    <slot>{formattedLabel}</slot>
</button>

<style type="text/scss" lang="scss">
    @import "./../../globals/_global-variables.scss";
    @import "./../../globals/mixins.scss";
    button {
        @include defaultText();

        display: inline-block;
        width: 3em;
        height: 3em;
        background-color: transparent;
        color: white;

        padding: 0.3em;

        border-radius: 0.1em;

        border: none;

        pointer-events: auto;
        transition: all 0.3s ease;

        cursor: pointer;

        &:focus {
            background-color: var(
                --v-button-focus-background-color,
                $button-focus-background-color
            );
            color: #fff;
        }

        &:active {
            transform: scale(0.9);
        }

        &:hover {
            background-color: var(
                --v-button-focus-background-color,
                $button-focus-background-color
            );
            color: #fff;
        }

        &[disabled] {
            background-color: var(
                --v-button-focus-background-color,
                $button-focus-background-color
            );
            color: #fff;
            cursor: not-allowed;
        }

        &.wide {
            height: 100%;
            width: 100%;
        }
    }
</style>
