import { getStreamType } from "../../vu-core/stream/get-stream-type";
import { isNullOrUndefined } from "../../vu-core/validation/is-null-or-undefined";
import type { IInstanceProvider } from "../../vu-player-models/configuration/instance-provider";
import type { ITimeService } from "../../vu-player-models/services/ITimeService";
import type { MilliSeconds } from "../../vu-player-models/time/MilliSeconds";
import type { Seconds } from "../../vu-player-models/time/seconds";
import type { SeekableRange } from "../../vu-player-models/time/seekable-range";

export class LiveTimeService implements ITimeService {
    constructor(private readonly instanceProvider: IInstanceProvider) {}

    private get instance() {
        return this.instanceProvider();
    }

    private get video(): HTMLVideoElement | undefined {
        return this.instance.getMediaElement();
    }

    get currentTime(): Seconds {
        let range = this.instance.seekRange();
        if (!range) {
            return NaN;
        }

        let currentTime = this.video?.currentTime ?? NaN;

        // TODO: HLS issues....
        // const isHls = getStreamType(this.video?.src) === "hls";
        // return isHls ? currentTime : currentTime - range.start;

        return currentTime - range.start;
    }

    get currentUTC(): Date {
        return this.instance.getPlayheadTimeAsDate() ?? new Date(NaN);
    }

    get duration(): Seconds {
        const playheadTime = this.instance.getPlayheadTimeAsDate();
        const presentationTime = this.instance.getPresentationStartTimeAsDate();

        if (playheadTime == null || presentationTime == null) {
            return NaN;
        }

        const diff: MilliSeconds =
            this.instance.getPlayheadTimeAsDate() -
            this.instance.getPresentationStartTimeAsDate();

        return diff / 1000;
    }

    get dvr(): Seconds {
        const range = this.seekableRange;
        return range.end - range.start;
    }

    get seekableRange(): SeekableRange {
        const range = this.instance.seekRange();
        if (!range) {
            return { start: NaN, end: NaN } as SeekableRange;
        }
        return range;
    }
}
