import { isArrayAndNotEmpty } from "../../vu-core/validation/is-array-and-not-empty";
import { isNullOrUndefined } from "../../vu-core/validation/is-null-or-undefined";
import type { IInstanceProvider } from "../../vu-player-models/configuration/instance-provider";
import type { ITimeService } from "../../vu-player-models/services/ITimeService";
import type { MilliSeconds } from "../../vu-player-models/time/MilliSeconds";
import type { Seconds } from "../../vu-player-models/time/seconds";
import type { SeekableRange } from "../../vu-player-models/time/seekable-range";

export class VodTimeService implements ITimeService {
    constructor(private readonly instanceProvider: IInstanceProvider) {}

    private get instance() {
        return this.instanceProvider();
    }

    private get video(): HTMLVideoElement | undefined {
        return this.instance.getMediaElement();
    }

    get currentTime(): Seconds {
        return this.video?.currentTime ?? NaN;
    }

    get currentUTC(): Date {
        let programStart = this._getStartTimeOffset();
        const timestamp: MilliSeconds =
            (programStart + this.currentTime) * 1000;
        let playHeadDate = new Date(timestamp);
        return playHeadDate;
    }

    get duration(): Seconds {
        return this.video?.duration ?? NaN;
    }

    get seekableRange(): SeekableRange {
        const range = this.instance.seekRange();
        if (!range) {
            return { start: NaN, end: NaN } as SeekableRange;
        }
        return range;
    }

    get dvr(): Seconds {
        return NaN;
    }

    private _getStartTimeOffset(): Seconds {
        const currentVariant = this.instance
            .getVariantTracks()
            .find((variantTrack: any): boolean => {
                return variantTrack.active;
            });

        if (!currentVariant) {
            return NaN;
        }

        const variants = this.instance.getManifest()?.variants;
        if (!isArrayAndNotEmpty(variants)) {
            return NaN;
        }

        const activeVariant = variants.find((variant: any): boolean => {
            return currentVariant.id === variant.id;
        });

        if (isNullOrUndefined(activeVariant)) {
            return NaN;
        }

        let segIndex = this._findCurrentSegment(activeVariant);
        if (isNullOrUndefined(segIndex?.timestampOffset)) {
            segIndex = this._takeFirstTimeStamp(activeVariant);
        }

        if (isNullOrUndefined(segIndex?.timestampOffset)) {
            return NaN;
        }

        return Math.abs(segIndex.timestampOffset);
    }

    private _takeFirstTimeStamp(activeVariant) {
        // TODO: with edgeware stream
        const segIndex = activeVariant?.video?.segmentIndex?.a.find(
            (segment) => {
                return !isNullOrUndefined(segment.timestampOffset);
            }
        );
        return segIndex;
    }

    private _findCurrentSegment(activeVariant) {
        const currentTime = this.currentTime;

        const segIndex = activeVariant?.video?.segmentIndex?.a.find(
            (segment) => {
                const isAfterStart = segment.startTime < currentTime;
                const isBeforeEnd = segment.endTime > currentTime;
                return isAfterStart && isBeforeEnd;
            }
        );
        return segIndex;
    }
}
