<script lang="ts">
    import { getContext } from "svelte";
    import type { Writable } from "svelte/store";
    import type { State } from "../../../../types/state";
    import { formatVideoTrack } from "../../../../utils/tracks";
    let state: Writable<State> = getContext("state");

    import { v4 as uuid } from "uuid";

    const autoInputId = uuid();

    $: videoTracks =
        $state.videoTracks?.map((videoTrack: any) => {
            videoTrack.inputId = uuid();
            return videoTrack;
        }) ?? [];

    function handleChange(videoTrack?: any) {
        $state.adapter?.setAbr(!videoTrack);

        if (videoTrack) {
            $state.adapter?.setVideoTrack(videoTrack);
        }
    }
</script>

{#if $state.videoTracks.length <= 0}
    <div>No video tracks available</div>
{:else}
    <ul class="vch-video-tracks-menu-list">
        <li>
            <label for={autoInputId}>
                <input
                    id={autoInputId}
                    type="radio"
                    name="videotrack"
                    value="auto"
                    checked={$state.adapter?.abr ?? true}
                    on:input={() => handleChange()}
                />
                &nbsp;
                {formatVideoTrack($state.activeVideoTrack, true)}
            </label>
        </li>

        {#each videoTracks as videoTrack}
            <li>
                <label for={videoTrack.inputId}>
                    <input
                        id={videoTrack.inputId}
                        type="radio"
                        name="videotrack"
                        bind:value={videoTrack.id}
                        checked={videoTrack.isActive && !$state.adapter?.abr}
                        on:input={() => handleChange(videoTrack)}
                    />
                    &nbsp;{formatVideoTrack(videoTrack)}
                </label>
            </li>
        {/each}
    </ul>
{/if}

<style lang="scss">
    .vch-video-tracks-menu-list {
        list-style: none;
        padding-inline-start: 1em;
    }
</style>
