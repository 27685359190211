<script lang="ts">
    import { isChrome } from "../../../../../../modules/vu-core/browser/is-chrome";
    import {
        formatVideoTrack,
        formatAudioTrack,
        formatTextTrack,
    } from "../../../utils/tracks";
    import VchButton from "../../shared/VchButton.svelte";
    import VchIcon from "../../shared/VchIcon.svelte";
    import VchAudioGraph from "./VchAudioGraph.svelte";
    import { getContext } from "svelte";
    import type { Writable } from "svelte/store";
    import type { State } from "../../../types/state";

    let state: Writable<State> = getContext("state");
    let stateShowInfoBox = $state.showInfoBox;

    $: showInfoBox =
        $state.hasCanplayFired && $stateShowInfoBox && !$state.isSettingsOpen;
    $: wide = $state.isOverlayHidden;

    function unPin() {
        $stateShowInfoBox = false;
    }
</script>

{#if showInfoBox}
    <div class="vch-info-box" class:wide>
        <div class="info">
            <table>
                {#if isChrome()}
                    <tr>
                        <th>Audio graph:</th>
                        <td>
                            <VchAudioGraph />
                        </td>
                    </tr>
                {/if}
                <tr>
                    <th>Source type:</th>
                    <td>{$state.sourceType}</td>
                </tr>
                <tr>
                    <th>State:</th>
                    <td>{$state.playerState}</td>
                </tr>
                <tr>
                    <th>Video:</th>
                    <td>
                        {formatVideoTrack(
                            $state.activeVideoTrack,
                            $state.adapter?.abr ?? true
                        )}
                    </td>
                </tr>
                <tr>
                    <th>Frame rate:</th>
                    <td>
                        {$state.activeVideoTrack.frameRate.toFixed(3)} fps
                    </td>
                </tr>
                <tr>
                    <th>Audio:</th>
                    <td>
                        {formatAudioTrack($state.activeAudioTrack)}
                    </td>
                </tr>
                <tr>
                    <th>Text:</th>
                    <td>
                        {formatTextTrack($state.activeTextTrack)}
                    </td>
                </tr>
                <tr>
                    <th>Current UTC:</th>
                    <td>{$state.currentUTC?.toJSON()}</td>
                </tr>
                <tr>
                    <th>Current time:</th>
                    <td>{$state.currentTime} sec.</td>
                </tr>
                {#if $state.isLive}
                    <tr>
                        <th>DVR:</th>
                        <td>{$state.dvr} sec.</td>
                    </tr>
                {:else}
                    <tr>
                        <th>Duration:</th>
                        <td>{$state.duration} sec.</td>
                    </tr>
                {/if}

                <tr>
                    <th>Seek range:</th>
                    <td>
                        {$state.seekableRange?.start.toFixed(3)} /{" "}
                        {$state.seekableRange?.end.toFixed(3)}
                    </td>
                </tr>
            </table>

            <div class="side-pin">
                <VchButton label="unpin" on:click={unPin} wide={true}>
                    <VchIcon type="pin" />
                </VchButton>
            </div>
        </div>
    </div>
{/if}

<style lang="scss">
    .vch-info-box {
        width: calc(100% - 2em);
        height: calc(100% - 2em);
        display: flex;
        justify-content: flex-end;
        padding: 1em;
        transition: all 1s ease-out;
        position: absolute;
        top: 0;

        &.wide {
            .info {
                max-height: calc(100% - 1em - 2px);
            }
        }

        .info {
            z-index: 1;
            overflow: auto;
            height: min-content;
            max-height: calc(100% - 6em);
            max-width: 100%;
            background-color: rgba(101, 101, 101, 0.4);
            border: 1px solid rgb(101, 101, 101);
            padding: 0.5em;
            display: flex;

            // &.wide {
            //     max-height: calc(100% - 2em);
            // }

            table {
                border-collapse: collapse;
                tr {
                    th {
                        text-align: end;
                        font-weight: normal;
                    }

                    td {
                        font-weight: bold;
                    }

                    & > * {
                        padding: 0.2em;
                        font-size: 0.8em;
                    }
                }
            }
        }
        .side-pin {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            width: 3em;
            margin-left: 1em;
        }
    }
</style>
