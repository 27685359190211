export type VideoTrack = {
    id: string;
    bitrate: number;
    height: number;
    width: number;
    label?: string;
    isActive: boolean;
    frameRate?: number;
};

export const EmptyVideoTrack: VideoTrack = {
    id: "",
    label: "",
    bitrate: 0,
    height: 0,
    width: 0,
    isActive: false,
    frameRate: NaN,
};
