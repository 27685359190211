import type { DRMProviderConfig } from "../../vu-player-models/drm/drm-provider-config";

export function createPlayreadyConfiguration(
    token: string,
    provider: DRMProviderConfig = {
        laURL: "https://playready-license.drm.technology/rightsmanager.asmx",
    }
) {
    return {
        "com.microsoft.playready": `${
            provider?.laURL
        }?token=${encodeURIComponent(token)}`,
    };
}
