import type { IEventSubscriber } from "./IEventSubscriber";

export function VideoElementEventSubscriber(
    video: HTMLVideoElement
): IEventSubscriber {
    return {
        on(nativeEventName: string, eventListener: EventListener) {
            video.addEventListener(nativeEventName, eventListener);

            return {
                off() {
                    video.removeEventListener(nativeEventName, eventListener);
                },
            };
        },
    };
}
