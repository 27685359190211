export const languageMappings = {
    ar: "عربى",
    da: "Dansk",
    de: "Deutsch",
    dv: "Descriptive Audio",
    el: "Ελληνικά",
    en: "English",
    es: "Español",
    fi: "Suomen Kieli",
    fr: "Français",
    he: "עִברִית",
    hi: "हिंदी",
    hu: "Magyar",
    id: "Bahasa Indonesia",
    is: "Íslensku",
    it: "Italiano",
    ja: "日本語",
    ko: "한국어",
    nl: "Nederlands",
    no: "Norsk",
    pl: "Polskie",
    pt: "Português",
    ru: "Pусский",
    sr: "Српски",
    sv: "Svenska",
    th: "ไทย",
    tr: "Türk",
    vi: "Tiếng Việt",
    zh: "中文",
    bg: "Bulgarian",
    cs: "Czech",
    et: "Estonian",
    ga: "Gaelic",
    hr: "Croatian",
    lv: "Latvian",
    lt: "Lithuanian",
    mt: "Maltese",
    ro: "Romanian",
    sk: "Slovakian",
    sl: "Slovenian",
    und: "Unknown",
};
export function mapTrackLang(lang = "und") {
    return languageMappings[lang] || lang;
}
