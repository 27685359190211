import { SupportedEvents } from "../../../../modules/vu-events/SupportedEvents";
export class ErrorsLogger {
    constructor(logError, adapter) {
        this.logError = logError;
        this.adapter = adapter;
        this.shakaErrorCodes = Object.entries((shaka === null || shaka === void 0 ? void 0 : shaka.util.Error.Code) || {});
        this.videoErrorCodes = Object.entries(MediaError);
        this.bind();
    }
    bind() {
        var _a;
        this.onErrorSubscription = (_a = this.adapter) === null || _a === void 0 ? void 0 : _a.on(SupportedEvents.Error, this.onError.bind(this));
    }
    unbind() {
        this.onErrorSubscription.off();
    }
    onError(error) {
        if (typeof error === "string") {
            this.logError(error);
            return;
        }
        if (error.type === "error") {
            this.logError(this.formatShakaError(error));
            return;
        }
        if (error instanceof MediaError) {
            this.logError(this.formatMediaError(error));
            return;
        }
        if (typeof error === "object") {
            this.logError(this.formatOtherErrors(error));
            return;
        }
        this.logError("An error happened, check console for more information.");
    }
    formatShakaError(shakaError) {
        var _a, _b, _c, _d;
        const code = (_c = (_b = (_a = shakaError) === null || _a === void 0 ? void 0 : _a.detail) === null || _b === void 0 ? void 0 : _b.code) !== null && _c !== void 0 ? _c : "Unknown code";
        const shakaErrorType = (_d = this.mappedCodeToName(code)) !== null && _d !== void 0 ? _d : "Unknown shaka error";
        return `Shaka Error: ${shakaErrorType} (${code})`;
    }
    formatMediaError({ mediaErrorCode }) {
        const [errorType] = this.videoErrorCodes.find(([, code]) => {
            return code === mediaErrorCode;
        }) || [];
        if (!errorType) {
            return "MediaError: Unknown";
        }
        else {
            return `MediaError: ${errorType} (${mediaErrorCode})`;
        }
    }
    formatOtherErrors(error) {
        try {
            return this.prettyJson(error);
        }
        catch (error) {
            console.warn("JSON Stringify failed");
        }
    }
    prettyJson(obj) {
        return JSON.stringify(Object.assign({}, obj), null, 4);
    }
    mappedCodeToName(shakaCode) {
        var _a;
        const [name] = (_a = this.shakaErrorCodes.find(([, code] = [, null]) => {
            return code === shakaCode;
        })) !== null && _a !== void 0 ? _a : [null];
        return name;
    }
}
