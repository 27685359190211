import { isNullOrUndefined } from "../validation/is-null-or-undefined";

export function isDisplayableNumber(num: number | undefined): boolean {
    return (
        !isNullOrUndefined(num) &&
        !isNaN(num) &&
        Infinity !== num &&
        -Infinity !== num
    );
}
