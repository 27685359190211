import type { IInstanceProvider } from "../../vu-player-models/configuration/instance-provider";

export async function createFairplayConfiguration(
    instanceProvider: IInstanceProvider,
    token: string,
    certURL: string,
    laURL = "https://fairplay-license.vudrm.tech/license"
) {
    const fairplayCertificate = await getClientFairplayCertificate(certURL);

    const fairplayConfig = {
        fairplayCertificate,
        laURL, // !!! This will be mutated by the initData
    };
    const shakaFairplayConfig = getSafariPlayerConfig(
        fairplayConfig,
        instanceProvider
    );

    instanceProvider()
        .getNetworkingEngine()
        .registerRequestFilter(
            createFairplayFilterCallback(fairplayConfig, token)
        );

    return shakaFairplayConfig;
}

async function getClientFairplayCertificate(certificateURL) {
    return fetch(certificateURL)
        .then((resp) => {
            if (!resp.ok) {
                throw new Error(`Cannot get certificate for ${certificateURL}`);
            }
            return resp.arrayBuffer();
        })
        .then((result) => {
            return new Uint8Array(result);
        });
}

function getSafariPlayerConfig(fairplayConfig, instanceProvider) {
    return {
        drm: {
            servers: {
                "com.apple.fps.1_0": fairplayConfig.laURL,
            },
            advanced: {
                "com.apple.fps.1_0": {
                    serverCertificate: fairplayConfig.fairplayCertificate,
                },
            },
            initDataTransform(initData, initDataType) {
                if (initDataType !== "skd") {
                    return initData;
                }

                fairplayConfig.laURL = shaka.util.StringUtils.fromBytesAutoDetect(
                    initData
                );

                // Create the initData for Fairplay
                const contentId = fairplayConfig.laURL.split("/").pop();
                const certificate = instanceProvider().drmInfo()
                    .serverCertificate;

                const modifiedData = shaka.util.FairPlayUtils.initDataTransform(
                    initData,
                    contentId,
                    certificate
                );

                return modifiedData;
            },
        },
    };
}

function createFairplayFilterCallback(fairplayConfig, token) {
    return function filterCallback(type, request) {
        if (type !== shaka.net.NetworkingEngine.RequestType.LICENSE) {
            return;
        }

        request.headers["X-VUDRM-TOKEN"] = token;
        request.headers["Content-Type"] = "ArrayBuffer";
        request.uris = [fairplayConfig.laURL.replace("skd", "https")];
    };
}
