import { isSafari } from "../vu-core/browser/is-safari";
import type { Predicate } from "../vu-core/types/predicate";
import type { IEventBus } from "../vu-events/IEventBus";
import type { IInstanceProvider } from "../vu-player-models/configuration/instance-provider";
import type { SourceConfig } from "../vu-player-models/configuration/source-config";
import type { ISourceLoader } from "../vu-player-models/player/ISourceLoader";
import { CustomEvents } from "./events/CustomEvents";
import {
    MapDRMSourceConfiguration,
    MapSourceConfiguration,
} from "./MapConfiguration";

export class SourceLoader implements ISourceLoader {
    private _sourceConfig: SourceConfig = {};
    private _src: string = "";

    constructor(
        private readonly instanceProvider: IInstanceProvider,
        public readonly eventBus: IEventBus
    ) {}

    public get sourceConfig() {
        return this._sourceConfig;
    }

    public get src() {
        return this._src;
    }

    async load(
        sourceConfig: SourceConfig,
        chooseHls: Predicate<void> = isSafari
    ): Promise<void> {
        const mappedConfig = MapSourceConfiguration(sourceConfig, chooseHls);

        const mappedDRMConfig = await MapDRMSourceConfiguration(
            sourceConfig,
            this.instanceProvider
        );

        const instance = this.instanceProvider();
        instance.configure({
            ...mappedDRMConfig,
        });

        this.saveLastSourceConfig(mappedConfig.manifestUri, sourceConfig);

        return instance.load(mappedConfig.manifestUri).then(() => {
            this.eventBus.emit(CustomEvents.SourceLoaded);
        });
    }

    private saveLastSourceConfig(src: string, sourceConfig: SourceConfig) {
        this._src = src;
        this._sourceConfig = sourceConfig;
    }
}
