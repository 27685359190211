import { isNullOrUndefined } from "../validation/is-null-or-undefined";

declare let window: any;

type PendingSDKRequest = {
    resolve: (value?: any) => void;
    reject: (reason?: any) => void;
};

const pendingSDKRequests: Record<string, PendingSDKRequest[]> = {};

const getGlobalModule = (moduleName: any) => {
    if (!isNullOrUndefined(window[moduleName])) {
        return window[moduleName];
    }

    if (window.exports && window.exports[moduleName]) {
        return window.exports[moduleName];
    }

    if (
        window.module &&
        window.module.exports &&
        window.module.exports[moduleName]
    ) {
        return window.module.exports[moduleName];
    }

    return undefined;
};

export const defaultScriptLoader = (
    src: string,
    onLoad: () => void = () => {},
    onError: (e: any) => void = () => {}
) => {
    const script = document.createElement("script");
    script.onload = onLoad;
    script.onerror = onError;
    script.src = src;

    document.head.insertAdjacentElement("beforeend", script);
};

export const loadSDK = <SDKType = any>(
    url: string,
    sdkGlobalVar: string,
    sdkNamespace?: string,
    isLoaded: (sdk: SDKType) => boolean = () => true,
    loadScript = defaultScriptLoader
) => {
    const existingGlobal = getGlobalModule(sdkGlobalVar);
    if (existingGlobal && isLoaded(existingGlobal)) {
        return Promise.resolve(existingGlobal);
    }

    return new Promise<SDKType>((resolve, reject) => {
        const sdkRequest = { resolve, reject };
        if (!isNullOrUndefined(pendingSDKRequests[url])) {
            pendingSDKRequests[url].push(sdkRequest);
            return;
        }

        pendingSDKRequests[url] = [sdkRequest];

        const onLoaded = (sdk: SDKType) => {
            pendingSDKRequests[url].forEach((request) => request.resolve(sdk));
        };

        if (!isNullOrUndefined(sdkNamespace)) {
            const previousOnReady: () => void = window[sdkNamespace] as any;
            window[sdkNamespace] = function () {
                if (!isNullOrUndefined(previousOnReady)) {
                    previousOnReady();
                }
                onLoaded(getGlobalModule(sdkGlobalVar));
            };
        }

        loadScript(
            url,
            () => {
                if (isNullOrUndefined(sdkNamespace)) {
                    onLoaded(getGlobalModule(sdkGlobalVar));
                }
            },
            (e) => {
                pendingSDKRequests[url].forEach((request) => {
                    request.reject(e);
                });
                delete pendingSDKRequests[url];
            }
        );
    });
};
