import { isDisplayableNumber } from "../number/is-displayable-number";
import { invalidDate } from "./invalid-date";

export function UTCToHHMMSS(utcDate: Date = invalidDate): string {
    if (!isDisplayableNumber(utcDate?.getTime())) return "00:00:00";

    let hours = String(utcDate.getUTCHours());
    let minutes = String(utcDate.getUTCMinutes());
    let seconds = String(utcDate.getUTCSeconds());

    return `${hours.padStart(2, "0")}:${minutes.padStart(
        2,
        "0"
    )}:${seconds.padStart(2, "0")}`;
}
