import { isArrayAndNotEmpty } from "../../vu-core/validation/is-array-and-not-empty";
import { isNullOrUndefined } from "../../vu-core/validation/is-null-or-undefined";
import type { IEventBus } from "../../vu-events/IEventBus";
import type { IInstanceProvider } from "../../vu-player-models/configuration/instance-provider";
import type { IAudioTrackService } from "../../vu-player-models/services/IAudioTrackService";
import type { AudioTrack } from "../../vu-player-models/tracks/audio-track";
import { EmptyAudioTrack } from "../../vu-player-models/tracks/audio-track";

import { CustomEvents } from "../events/CustomEvents";

export class AudioTrackService implements IAudioTrackService {
    constructor(
        private readonly instanceProvider: IInstanceProvider,
        public readonly customEventBus: IEventBus
    ) {}

    setAudioTrack(audioTrack: AudioTrack): Promise<void> {
        if (isNullOrUndefined(audioTrack)) {
            return Promise.reject("This audio track doesn't exist!");
        }

        let track = this.audioTracks.find((track) => {
            return track.id === (<AudioTrack>audioTrack).id;
        });

        if (isNullOrUndefined(track)) {
            return Promise.reject("This audio track doesn't exist!");
        }

        this.instance.selectAudioLanguage(track!.lang);

        this.customEventBus.emit(CustomEvents.AudioTrackChange, { track });
        return Promise.resolve();
    }

    private get instance(): any {
        return this.instanceProvider();
    }

    public get audioTracks(): AudioTrack[] {
        let variantTracks = this.instance.getVariantTracks();

        if (!isArrayAndNotEmpty(variantTracks)) {
            return [];
        }

        return variantTracks.reduce(filterVariantTracks, []);
    }

    public get activeAudioTrack(): AudioTrack {
        let track: unknown = this.audioTracks.find((audioTrack): boolean => {
            return audioTrack.isActive;
        });

        if (isNullOrUndefined(track)) {
            return EmptyAudioTrack;
        }

        return track as AudioTrack;
    }
}

function filterVariantTracks(audioTracks, currentVariantTrack): AudioTrack[] {
    const mappedVariantTrack = mapAudioTrack(currentVariantTrack);

    let audioTrackAlreadyAdded = audioTracks.find(
        (audioTrack: AudioTrack): boolean => {
            // Comment: because of the variantTracks matrix https://shaka-player-demo.appspot.com/docs/api/shakaExtern.html#.Track
            return (
                audioTrack.id === mappedVariantTrack.id &&
                audioTrack.lang === mappedVariantTrack.lang &&
                audioTrack.bitrate === mappedVariantTrack.bitrate
            );
        }
    );

    if (!audioTrackAlreadyAdded) {
        audioTracks.push(mappedVariantTrack);
        return audioTracks;
    }

    if (!!mappedVariantTrack.isActive) {
        audioTrackAlreadyAdded.isActive = true;
    }
    return audioTracks;
}

const mapAudioTrack = ({
    originalAudioId, // Tried with audioId not mapped by shaka
    language,
    label,
    active,
    audioBandwidth,
}: any): AudioTrack => {
    return {
        id: String(originalAudioId),
        lang: language,
        isActive: !!active,
        label: label ?? language,
        bitrate: Number(audioBandwidth),
    };
};
