export type ExtendedStorage = Storage & {
    getItemOrDefault: (
        key: string,
        defaultValue: string | null
    ) => string | null;
    getPrimitiveItem: (key: string) => unknown;
    getPrimitiveItemOrDefault: (
        key: string,
        defaultValue: unknown | null
    ) => unknown;
    setPrimitiveItem: (key: string, value: any) => void;
};

export function getExtendedLocalStorage(ls = localStorage): ExtendedStorage {
    Storage.prototype.getItemOrDefault = function getItemOrDefault(
        key: string,
        defaultValue: string | null = null
    ): string | null {
        const value = this.getItem(key);
        return value ?? defaultValue;
    };

    Storage.prototype.getPrimitiveItem = function getPrimitiveItem(
        key: string
    ): unknown {
        const strVal = this.getItemOrDefault(key);
        const value = JSON.parse(strVal);
        return value;
    };
    Storage.prototype.getPrimitiveItemOrDefault = function getPrimitiveItemOrDefault(
        key: string,
        defaultValue: unknown | null = null
    ): unknown {
        const value = this.getPrimitiveItem(key);
        return value ?? defaultValue;
    };

    Storage.prototype.setPrimitiveItem = function setPrimitiveItem(
        key: string,
        value: any
    ): void {
        this.setItem(key, JSON.stringify(value));
    };

    return localStorage as ExtendedStorage;
}
