<script lang="ts">
    import { getContext } from "svelte";
    import VchButton from "../shared/VchButton.svelte";
    import VchIcon from "../shared/VchIcon.svelte";
    import type { Writable } from "svelte/store";
    import type { State } from "../../types/state";
    import type { SourceConfig } from "../../../../../modules/vu-player-models/configuration/source-config";

    let state: Writable<State> = getContext("state");

    $: disabled = !$state.hasCanplayFired;
    $: type = ["uninitialised", "paused"].includes($state.playerState)
        ? "play"
        : ["playing", "waiting", "seeking"].includes($state.playerState)
        ? "pause"
        : ["error", "ended"].includes($state.playerState)
        ? "replay"
        : "";
    $: playPauseSuffix = type === "replay" ? "" : " (k)";
    $: label = type + playPauseSuffix;

    function onClick() {
        if (type === "play") {
            $state.adapter?.play();
        } else if (type === "pause") {
            $state.adapter?.pause();
        } else if (type === "replay") {
            if ($state.sourceConfig) {
                let srcConf = $state.sourceConfig as SourceConfig;
                $state.adapter?.load(srcConf);
            }
        }
    }
</script>

<VchButton on:click={onClick} {disabled} {label}>
    <VchIcon {type} {disabled} />
</VchButton>
