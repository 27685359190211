export function _throw(
    {
        debug = false,
        skipThrow = false,
        error = "something wrong happened",
        loggerFunction = console.error.bind(console),
    }: throwConfig = {
        debug: false,
        skipThrow: false,
        error: "something wrong happened",
        loggerFunction: console.error.bind(console),
    }
): void {
    if (debug) {
        debugger;
    }

    if (skipThrow) {
        loggerFunction(error);
        return;
    }

    if (typeof error === "string") {
        throw new Error(error);
    }

    throw error;
}

type throwConfig = {
    debug?: boolean;
    skipThrow?: boolean;
    error?: string | Error;
    loggerFunction?: Function;
};
