import type { IEventBus } from "../../vu-events/IEventBus";
import type { IEventSubscriber } from "../../vu-events/IEventSubscriber";
import { SupportedEvents } from "../../vu-events/SupportedEvents";
import type { IInstanceProvider } from "../../vu-player-models/configuration/instance-provider";
import type { PlayerState } from "../../vu-player-models/player/PlayerState";
import type { IStateService } from "../../vu-player-models/services/IStateService";
import { CustomEvents } from "../events/CustomEvents";

export class StateService implements IStateService {
    constructor(
        public readonly instanceProvider: IInstanceProvider,
        public readonly eventSubscriber: IEventSubscriber,
        public readonly eventBus: IEventBus,
        // "playing" | "waiting" | "paused" | "ended" | "seeking" | "error"
        private _state: PlayerState = "uninitialised"
    ) {
        eventSubscriber.on(
            SupportedEvents.TimeUpdate,
            this.setState("playing")
        );
        eventSubscriber.on(SupportedEvents.Playing, this.setState("playing"));

        eventSubscriber.on(
            SupportedEvents.SourceLoaded,
            this.setState("paused")
        );
        eventSubscriber.on(SupportedEvents.CanPlay, this.setState("paused"));
        eventSubscriber.on(SupportedEvents.Pause, this.setState("paused"));
        eventSubscriber.on(SupportedEvents.Seeked, this.setState("paused"));

        eventSubscriber.on(SupportedEvents.Waiting, this.setState("waiting"));
        eventSubscriber.on(SupportedEvents.Ended, this.setState("ended"));
        eventSubscriber.on(SupportedEvents.Seeking, this.setState("seeking"));
        eventSubscriber.on(SupportedEvents.Error, this.setState("error"));
    }

    get video(): HTMLVideoElement {
        return this.player.getMediaElement();
    }

    get player() {
        return this.instanceProvider();
    }

    get state() {
        return this._state;
    }

    setState(state: PlayerState) {
        return () => {
            this._state = state;
            this.eventBus.emit(CustomEvents.StateChange);
        };
    }
}
