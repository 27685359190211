import { _throw } from "../error/throw";
import { isNullOrUndefined } from "./is-null-or-undefined";

function assertTruthy(input: any, message: any): void {
    if (!input) {
        _throw({ error: new Error(message) });
    }
}

function assertDefined(input: any, parameterName: any): void {
    assertTruthy(
        !isNullOrUndefined(input),
        `${parameterName} must be defined and not null!`
    );
}

export { assertDefined, assertTruthy };
