import { writable } from "svelte/store";
export function watcher(initialValue, watchFunction) {
    const { subscribe, update } = writable(initialValue);
    return {
        subscribe,
        set: (value) => {
            update((oldvalue) => {
                watchFunction(oldvalue, value);
                return value;
            });
        },
    };
}
