import { isNullOrUndefined } from "../../vu-core/validation/is-null-or-undefined";
import { isValidNumber } from "../../vu-core/validation/isValidNumber";
import type { IInstanceProvider } from "../../vu-player-models/configuration/instance-provider";
import type { IPlaybackService } from "../../vu-player-models/services/IPlaybackService";
import type { Seconds } from "../../vu-player-models/time/seconds";
import type { SeekableRange } from "../../vu-player-models/time/seekable-range";

export class PlaybackService implements IPlaybackService {
    constructor(
        private readonly instanceProvider: IInstanceProvider,
        readonly isLive = false
    ) {}
    private get instance(): any {
        return this.instanceProvider();
    }

    private get video(): any {
        return this.instance.getMediaElement();
    }

    public play(): Promise<void> {
        this.video.play();
        return Promise.resolve();
    }

    public pause(): Promise<void> {
        this.video.pause();
        return Promise.resolve();
    }

    public get isPaused(): boolean {
        return this.video.paused;
    }

    public seek(seconds: Seconds): Promise<void> {
        if (!isValidNumber(seconds)) {
            return Promise.reject("Value to skip must be a number in seconds");
        }

        const seekRange = this.seekableRange;
        let seekTo = Math.abs(seconds);

        // Guard to avoid the video element 💥
        if (seekTo < seekRange.start) {
            seekTo = seekRange.start;
        }

        if (seekTo > seekRange.end) {
            seekTo = seekRange.end;
        }

        this.video.currentTime = seekTo;
        return Promise.resolve();
    }

    private get seekableRange(): SeekableRange {
        const range = this.instance.seekRange();
        if (!range) {
            return { start: NaN, end: NaN } as SeekableRange;
        }
        return range;
    }

    public skipTo(seconds: Seconds): Promise<void> {
        if (!isValidNumber(seconds)) {
            return Promise.reject("Value to skip must be a number in seconds");
        }

        this.seek(this.video.currentTime + seconds);
        return Promise.resolve();
    }

    public seekToDate(when: Date): Promise<void> {
        if (!this.isLive) {
            return Promise.reject(
                "The configuration says that the stream is not LIVE."
            );
        }

        const presentationStartTime = this.instance.getPresentationStartTimeAsDate();

        if (isNullOrUndefined(presentationStartTime)) {
            return Promise.reject("Couldn't retrieve prensentation ");
        }

        const seconds =
            (when.getTime() - presentationStartTime.getTime()) / 1000;
        this.seek(seconds);

        return Promise.resolve();
    }

    public seekPercent(percent: number): Promise<void> {
        if (percent > 100 || percent < 0) {
            return Promise.reject(
                "Please provide a percent value between 0 and 100"
            );
        }

        const seekableRange = this.instance.seekRange();
        const duration = seekableRange.end - seekableRange.start;
        const seekTimeFromStart = (duration * percent) / 100;
        if (this.isLive) {
            this.seek(seekTimeFromStart + seekableRange.start);
        } else {
            this.seek(seekTimeFromStart);
        }

        return Promise.resolve();
    }

    public goToLive(): Promise<void> {
        if (!this.isLive) {
            return Promise.reject(
                "The configuration says that the stream is not LIVE."
            );
        }

        const seekRange = this.instance.seekRange();
        if (isNullOrUndefined(seekRange)) {
            return Promise.reject("No seekRange available.");
        }

        const { end } = seekRange;
        if (!isValidNumber(end)) {
            return Promise.reject("No seekRange end available..");
        }

        this.seek(end);
        return Promise.resolve();
    }
}
