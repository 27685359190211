export function isPipAvailable() {
    return !!(document as any).pictureInPictureEnabled;
}

export function isPipUsed() {
    return !!(document as any).pictureInPictureElement;
}

export function togglePictureInPicture(video: HTMLVideoElement) {
    let isPipEnabled = isPipAvailable();
    if (isPipUsed()) {
        (document as any).exitPictureInPicture();
    } else {
        if (isPipEnabled) {
            (video as any).requestPictureInPicture();
        }
    }
}
