import type { Seconds } from "../../vu-player-models/time/seconds";
import { isDisplayableNumber } from "../number/is-displayable-number";

export function toHHMMSS(seconds: Seconds = NaN): string {
    if (!isDisplayableNumber(seconds)) return "00:00:00";

    let totalSeconds = Math.floor(seconds);
    if (totalSeconds < 0) {
        totalSeconds = totalSeconds * -1;
    }

    let remainingHours = Math.floor(totalSeconds / 3600);
    let remainingMinutes = Math.floor(
        (totalSeconds - remainingHours * 3600) / 60
    );
    let remainingSeconds =
        totalSeconds - remainingHours * 3600 - remainingMinutes * 60;

    let displayHours = remainingHours.toString();
    let displayMinutes = remainingMinutes.toString();
    let displaySeconds = remainingSeconds.toString();

    if (remainingMinutes < 10) {
        displayMinutes = "0" + remainingMinutes;
    }

    if (remainingSeconds < 10) {
        displaySeconds = "0" + remainingSeconds;
    }

    if (remainingHours < 10) {
        displayHours = "0" + remainingHours;
    }

    if (seconds < 0) {
        return `-${displayHours}:${displayMinutes}:${displaySeconds}`;
    } else {
        return `${displayHours}:${displayMinutes}:${displaySeconds}`;
    }
}
