<script lang="ts">
    import { getContext } from "svelte";
    import type { Writable } from "svelte/store";
    import type { State } from "../../../../types/state";
    import { formatAudioTrack } from "../../../../utils/tracks";
    let state: Writable<State> = getContext("state");

    import { v4 as uuid } from "uuid";

    $: audioTracks =
        $state.audioTracks?.map((audioTrack: any) => {
            audioTrack.inputId = uuid();
            return audioTrack;
        }) ?? [];

    function handleChange(audioTrack?: any) {
        $state.adapter?.setAudioTrack(audioTrack);
    }
</script>

{#if audioTracks.length <= 0}
    <div>No audio tracks available</div>
{:else}
    <ul class="vch-audio-tracks-menu-list">
        {#each audioTracks as audioTrack}
            <li>
                <label for={audioTrack.inputId}>
                    <input
                        id={audioTrack.inputId}
                        type="radio"
                        name="audioTrack"
                        bind:value={audioTrack.id}
                        checked={audioTrack.isActive}
                        on:input={() => handleChange(audioTrack)}
                    />
                    &nbsp;{formatAudioTrack(audioTrack)}
                </label>
            </li>
        {/each}
    </ul>
{/if}

<style lang="scss">
    .vch-audio-tracks-menu-list {
        list-style: none;
        padding-inline-start: 1em;
    }
</style>
