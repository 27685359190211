import { isSafari } from "../vu-core/browser/is-safari";
import type { Predicate } from "../vu-core/types/predicate";
import { assertTruthy } from "../vu-core/validation/assert";
import { isStringAndNotEmpty } from "../vu-core/validation/is-string-and-not-empty";
import type { IInstanceProvider } from "../vu-player-models/configuration/instance-provider";
import type { PlayerConfig } from "../vu-player-models/configuration/player-config";
import type { SourceConfig } from "../vu-player-models/configuration/source-config";
import { createFairplayConfiguration } from "./drm/createFairplayConfiguration";
import { createPlayreadyConfiguration } from "./drm/createPlayreadyConfiguration";
import { createWidevineConfiguration } from "./drm/createWidevineConfiguration";

type ShakaSourceConfiguration = { manifestUri: string; poster?: string };

export function MapSourceConfiguration(
    configuration: SourceConfig,
    chooseHls: Predicate<void>
): ShakaSourceConfiguration {
    let manifestUri;
    const dashAvailable = isStringAndNotEmpty(configuration.dash);
    const hlsAvailable = isStringAndNotEmpty(configuration.hls);

    assertTruthy(
        dashAvailable || hlsAvailable,
        "Source configuration not valid, you need to provide at least a dash or hls url"
    );

    if (dashAvailable && !hlsAvailable) {
        manifestUri = configuration.dash;
    }

    if (!dashAvailable && hlsAvailable) {
        manifestUri = configuration.hls;
    }

    if (dashAvailable && hlsAvailable) {
        manifestUri = chooseHls() ? configuration.hls : configuration.dash;
    }

    const sourceConfig: ShakaSourceConfiguration = { manifestUri };

    if (configuration.poster) {
        sourceConfig.poster = configuration.poster;
    }

    return sourceConfig;
}

export async function MapDRMSourceConfiguration(
    configuration: SourceConfig,
    instanceProvider: IInstanceProvider
): Promise<any> {
    const token = configuration?.drm?.token;
    if (!token) {
        return {};
    }

    if (isSafari()) {
        const certURL = configuration?.drm?.providers?.fairplay?.certificateURL;

        if (!certURL) {
            throw new Error(
                "Missing fairplay provider certificateURL (with client name)"
            );
        }

        return await createFairplayConfiguration(
            instanceProvider,
            token,
            certURL,
            configuration?.drm?.providers?.fairplay?.laURL
        );
    }

    const playready = createPlayreadyConfiguration(
        token,
        configuration.drm?.providers?.playready
    );
    const widevine = createWidevineConfiguration(
        token,
        configuration.drm?.providers?.widevine
    );

    return {
        drm: {
            servers: {
                ...playready,
                ...widevine,
            },
        },
    };
}

export function MapPlayerConfiguration(configuration: PlayerConfig): any {
    let playerConfig: any = { ...configuration };

    delete playerConfig.container;
    delete playerConfig.autoplay;
    delete playerConfig.muted;
    delete playerConfig.controls;
    delete playerConfig.loop;
    delete playerConfig.poster;

    return playerConfig;
}
