<script lang="ts">
    import { getContext } from "svelte";
    import type { Writable } from "svelte/store";
    import type { State } from "../../../../types/state";
    import { formatTextTrack } from "../../../../utils/tracks";
    let state: Writable<State> = getContext("state");

    import { v4 as uuid } from "uuid";

    const autoInputId = uuid();

    $: textTracks =
        $state.textTracks?.map((textTrack: any) => {
            textTrack.inputId = uuid();
            return textTrack;
        }) ?? [];

    function handleChange(textTrack?: any) {
        $state.adapter?.setTextTrack(textTrack);
    }
</script>

{#if textTracks.length <= 0}
    <div>No text tracks available</div>
{:else}
    <ul class="vch-text-tracks-menu-list">
        <li>
            <label for={autoInputId}>
                <input
                    id={autoInputId}
                    type="radio"
                    name="texttrack"
                    value="auto"
                    checked={!$state.activeTextTrack?.isActive}
                    on:input={() => handleChange(null)}
                />
                &nbsp;None
            </label>
        </li>

        {#each textTracks as textTrack}
            <li>
                <label for={textTrack.inputId}>
                    <input
                        id={textTrack.inputId}
                        type="radio"
                        name="texttrack"
                        bind:value={textTrack.id}
                        checked={textTrack.isActive}
                        on:input={() => handleChange(textTrack)}
                    />
                    &nbsp;{formatTextTrack(textTrack)}
                </label>
            </li>
        {/each}
    </ul>
{/if}

<style lang="scss">
    .vch-text-tracks-menu-list {
        list-style: none;
        padding-inline-start: 1em;
    }
</style>
