import { mapTrackLang } from "./language-mappings";
import { isStringAndNotEmpty } from "../../../../modules/vu-core/validation/is-string-and-not-empty";
export function formatVideoTrack(videoTrack, isAbrEnabled = false) {
    var _a;
    const kiloBitrate = ((_a = videoTrack === null || videoTrack === void 0 ? void 0 : videoTrack.bitrate) !== null && _a !== void 0 ? _a : NaN) / 1000;
    let formattedVideoTrack = `${kiloBitrate} kbit/s, ${videoTrack === null || videoTrack === void 0 ? void 0 : videoTrack.width}x${videoTrack === null || videoTrack === void 0 ? void 0 : videoTrack.height} px`;
    if (isAbrEnabled) {
        return `Auto (${formattedVideoTrack})`;
    }
    return formattedVideoTrack;
}
export function formatAudioTrack(track) {
    const mappedTrack = getLangOrMapLabel(track);
    return mappedTrack
        ? `${mappedTrack} (${track === null || track === void 0 ? void 0 : track.lang})`
        : `No Audio track active`;
}
export function formatTextTrack(track) {
    var _a;
    const mappedTrack = getLangOrMapLabel(track);
    let langOrKind = (_a = track === null || track === void 0 ? void 0 : track.lang) !== null && _a !== void 0 ? _a : "n/a";
    if (track.kind === "caption") {
        langOrKind = "CC";
    }
    return mappedTrack
        ? `${mappedTrack} (${langOrKind})`
        : `No Text track active`;
}
function getLangOrMapLabel(track) {
    if (isStringAndNotEmpty(track === null || track === void 0 ? void 0 : track.label)) {
        return track.label;
    }
    if (isStringAndNotEmpty(track === null || track === void 0 ? void 0 : track.lang)) {
        let lang = mapTrackLang(track === null || track === void 0 ? void 0 : track.lang);
        return lang;
    }
}
